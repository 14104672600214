@use 'vars';
@use 'libs/styles/dark-mode';

.ng-select {
  position: relative;
}

.ng-select.ng-select-opened.ng-select-clearable
  .ng-select-container.ng-has-value:hover
  .ng-clear-wrapper {
  opacity: 0;
  pointer-events: none;
}

.ng-arrow-wrapper {
  display: none;
}
.ng-select-container {
  height: 100%;
}

.ng-select.ng-select-opened .ng-select-container {
  z-index: 101 !important;
}

.ng-select.ng-select-opened.ng-select-clearable
  .ng-select-container.ng-has-value:hover
  .ng-arrow-wrapper {
  opacity: 1;
  pointer-events: auto;
}

.ng-select.ng-select-opened > .ng-select-container {
  background: vars.$gray-10;
  box-shadow: 0 0 0 2px vars.$gray-9;
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow-wrapper {
  transform: rotate(180deg);
  opacity: 1;
}

[dir='rtl']
  .ng-select.ng-select-opened
  > .ng-select-container
  .ng-arrow-wrapper {
  transform: rotate(-180deg);
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: vars.$gray-8;
}

.ng-select.ng-select-disabled > .ng-select-container:hover {
  cursor: not-allowed;
}

.ng-select.ng-select-disabled
  > .ng-select-container
  .ng-value-container
  .ng-value {
  color: vars.$neutral-5;
}

.ng-select .ng-has-value .ng-placeholder {
  display: none;
}

.ng-select.ng-select-clearable
  .ng-select-container.ng-has-value:hover
  .ng-clear-wrapper {
  opacity: 1;
}

.ng-select.ng-select-clearable
  .ng-select-container.ng-has-value:hover
  .ng-arrow-wrapper {
  opacity: 0;
  pointer-events: none;
}

.ng-select .ng-select-container {
  color: vars.$neutral-1;
  background-color: vars.$gray-10;
  border-radius: 4px;
  min-height: 30px;
  align-items: center;
  transition: width 0.3s;
  box-sizing: border-box;
  padding-right: 11px;
  padding-left: 11px;
}

.ng-select .ng-select-container .ng-input {
  line-height: 22px;
}

.ng-select .ng-select-container .ng-input input {
  color: vars.$neutral-1;
  border: 0 !important;
  height: 32px;
}
.ng-dropdown-header {
  background-color: vars.$DM600;
  .ng-dropdown-input {
    color: vars.$neutral-1 !important;
    border: 0 !important;
    height: 32px;
    background-color: vars.$gray-10;
    padding-left: 12px;
  }
}

.ng-select .ng-select-container .ng-value-container {
  align-items: center;
}

.ng-select .ng-select-container .ng-value-container .ng-value {
  font-size: 14px;
  color: vars.$neutral-1;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  font-size: 14px;
  color: vars.$neutral-7;
}

.ng-select.ng-select-single.ng-select-opened .ng-select-container .ng-value {
  opacity: 0.4;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 2px;
  left: 0;
  padding-left: 10px;
  padding-right: 50px;
}

[dir='rtl']
  .ng-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-input {
  padding-right: 10px;
  padding-left: 50px;
}

.ng-select.ng-select-multiple.ng-select-disabled
  > .ng-select-container
  .ng-value-container
  .ng-value {
  background-color: vars.$gray-8;
  border: 1px solid vars.$gray-9;
}

.ng-select.ng-select-multiple.ng-select-disabled
  > .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-label {
  padding: 0 5px;
}

.ng-select.ng-select-multiple .ng-select-container {
  min-height: 42px;
  padding-left: 12px;
  overflow-x: scroll;
}

[dir='rtl'] .ng-select.ng-select-multiple .ng-select-container {
  padding-right: 5px;
  padding-left: 11px;
}

[dir='rtl']
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  margin-left: 0;
  margin-right: 6px;
}

.ng-select.ng-select-multiple:not(.fullInput)
  .ng-select-container
  .ng-value-container {
  padding-bottom: 3px;
  flex-wrap: nowrap !important;
}
.ng-select.ng-select-multiple.fullInput .ng-select-container {
  height: 100%;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  margin-top: 3px;
  margin-right: 4px;
  font-size: 0.9em;
  background-color: vars.$gray-8;
  border-radius: 2px;
  height: 24px;
  line-height: 22px;
  display: flex;
  flex-direction: row-reverse;
}

[dir='rtl']
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  margin-right: 0;
  margin-left: 4px;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value.ng-value-disabled {
  background-color: vars.$neutral-5;
  color: vars.$gray-8;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value.ng-value-disabled
  .ng-value-label {
  padding-left: 5px;
}

[dir='rtl']
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value.ng-value-disabled
  .ng-value-label {
  padding-left: 0;
  padding-right: 5px;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-label {
  display: inline-block;
  padding: 0 5px;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon {
  display: inline-block;
  padding: 0 7px 0 2px;
  color: vars.$neutral-7;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  top: 50%;
  height: 20px;
  margin-top: -10px;
  margin-left: 6px;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input {
  height: 24px;
  margin-top: 3px;
}

.ng-select .ng-clear-wrapper {
  opacity: 0;
  color: vars.$neutral-5;
  background: vars.$neutral-5;
  width: 12px;
  height: 12px;
  text-align: center;
  border-radius: 12px;
  vertical-align: middle;
  transition: color 0.3s ease;
  position: absolute;
  right: 13px;
}

[dir='rtl'] .ng-select .ng-clear-wrapper {
  left: 13px;
  right: auto;
}

.ng-select .ng-clear-wrapper .ng-clear {
  color: vars.$gray-10;
  font-size: 9px;
  position: absolute;
  left: 3px;
  line-height: 12px;
}

.ng-select .ng-spinner-zone {
  padding: 5px 5px 0 0;
}

[dir='rtl'] .ng-select .ng-spinner-zone {
  padding: 5px 0 0 5px;
}

.ng-select .ng-arrow-wrapper {
  width: 16px;
  height: 26px;
  transition: transform 0.3s;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-style: solid;
  border-color: vars.$neutral-1 vars.$neutral-1 transparent transparent;
  border-width: 3px;
  height: 8px;
  width: 8px;
  top: -2px;
  transform: rotate(135deg);
  color: vars.$neutral-5;
}

.ng-dropdown-panel {
  background-color: vars.$gray-10;
  box-shadow: 0 2px 8px vars.$gray-10;
  border-radius: 4px;
  border: 2px solid dark-mode.$border_02;
  margin-top: 4px;
  margin-bottom: 4px;
  left: 0;
}

.ng-dropdown-panel.ng-select-bottom {
  top: 100%;
}

.ng-dropdown-panel.ng-select-top {
  bottom: 100%;
}

.ng-dropdown-panel .ng-dropdown-header {
  padding: 5px 7px;
}

.ng-dropdown-panel .ng-dropdown-footer {
  padding: 5px 7px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  user-select: none;
  padding: 4px 12px;
  cursor: pointer;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
  cursor: default;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background-color: vars.$neutral-7;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected {
  background-color: vars.$gray-5;
  color: vars.$neutral-3;
  font-weight: 600;
}

.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-optgroup.ng-option-selected.ng-option-marked {
  background: dark-mode.$field_background_hover_01;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: vars.$DM600;
  color: vars.$neutral-1;
  padding: 5px 12px;
  transition: background 0.3s ease;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: vars.$DM200;
  color: vars.$neutral-3;
  font-weight: 600;
}

.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked {
  color: vars.$neutral-1;
  background-color: vars.$DM200;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background: vars.$DM300;
  color: vars.$neutral-1;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: vars.$neutral-5;
  cursor: not-allowed;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-left: 20px;
}

[dir='rtl']
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-child {
  padding-right: 20px;
  padding-left: 0;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label {
  font-size: 14px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  font-size: 80%;
  font-weight: 400;
  padding-right: 5px;
}

[dir='rtl']
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option
  .ng-tag-label {
  padding-left: 5px;
  padding-right: 0;
}

[dir='rtl'] .ng-dropdown-panel {
  direction: rtl;
  text-align: right;
}
