// Do not edit directly
// Generated on Tue, 20 Jun 2023 16:38:25 GMT

$dark-mode-alpha-blue-b-60-a: #347af499;
$dark-mode-alpha-neutral-dm-04-a: #aecafb0a;
$dark-mode-alpha-neutral-dm-05-a: #aecafb0d;
$dark-mode-alpha-neutral-dm-08-a: #aecafb14;
$dark-mode-alpha-neutral-dm-15-a: #aecafb26;
$dark-mode-alpha-neutral-dm-24-a: #aecafb3d;
$dark-mode-alpha-neutral-dm-45-a: #aecafb73;
$dark-mode-standard-green-g-300: #2c9b6c;
$dark-mode-standard-green-g-100: #33bd83;
$dark-mode-standard-green-g-80: #5cca9c;
$dark-mode-standard-green-g-40: #ade5cd;
$dark-mode-standard-yellow-y-300: #c5a12a;
$dark-mode-standard-yellow-y-100: #f2c530;
$dark-mode-standard-yellow-y-80: #f5d159;
$dark-mode-standard-yellow-y-40: #fae8ac;
$dark-mode-standard-red-r-300: #af3434;
$dark-mode-standard-red-r-100: #d63c3c;
$dark-mode-standard-red-r-80: #de6363;
$dark-mode-standard-red-r-40: #efb1b1;
$dark-mode-standard-blue-primary-b-700: #103066;
$dark-mode-standard-blue-primary-b-500: #194999;
$dark-mode-standard-blue-primary-b-300: #2161cc;
$dark-mode-standard-blue-primary-b-100-primary: #347af4;
$dark-mode-standard-blue-primary-b-60: #85aff8;
$dark-mode-standard-blue-primary-b-40: #aecafb;
$dark-mode-standard-blue-primary-b-20: #d6e4fd;
$dark-mode-standard-neutral-dm-base: #090a0b;
$dark-mode-standard-neutral-dm-900: #101213;
$dark-mode-standard-neutral-dm-800: #17191c;
$dark-mode-standard-neutral-dm-700: #202328;
$dark-mode-standard-neutral-dm-600: #262b31;
$dark-mode-standard-neutral-dm-500: #2f353d;
$dark-mode-standard-neutral-dm-400: #373f49;
$dark-mode-standard-neutral-dm-300: #3f4855;
$dark-mode-standard-neutral-dm-200: #475262;
$dark-mode-standard-neutral-dm-100: #515f70;
$dark-mode-standard-neutral-dm-90: #5a697c;
$dark-mode-standard-neutral-dm-80: #65758b;
$dark-mode-standard-neutral-dm-70: #718298;
$dark-mode-standard-neutral-dm-60: #8392a5;
$dark-mode-standard-neutral-dm-50: #929fb0;
$dark-mode-standard-neutral-dm-40: #a3aebd;
$dark-mode-standard-neutral-dm-30: #b5bec9;
$dark-mode-standard-neutral-dm-20: #c7ced6;
$dark-mode-standard-neutral-dm-10: #d9dde3;
$dark-mode-standard-neutral-dm-00: #eceef1;
