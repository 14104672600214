@use 'libs/styles/vars';

@each $name, $value in vars.$regulars {
  .#{$name} {
    font-size: $value;
  }
}

@each $name, $value in vars.$regulars {
  .i#{$name} {
    font-size: $value !important;
  }
}

.font-bold {
  font-weight: bold;
}

.font-semibold {
  font-weight: 500;
  line-height: 24px;
}

.font-lighter {
  font-weight: 100;
  line-height: 20px;
}

.font-light {
  font-weight: 300;
  line-height: 20px;
}

.font-italic {
  font-style: italic;
}

@each $name, $value in vars.$font-weights {
  .fw-#{$name} {
    font-weight: $value;
  }
}

@each $name, $value in vars.$font-weights {
  .ifw-#{$name} {
    font-weight: $value !important;
  }
}

@each $name, $value in vars.$line-heights {
  .lh-#{$name} {
    line-height: $value;
  }
}

@each $name, $value in vars.$line-heights {
  .ilh-#{$name} {
    line-height: $value !important;
  }
}
