@use 'sass:map';
// sass-lint:disable no-color-hex
// Color system
$neutral-1: #fff;
$neutral-2: #fafafa;
$neutral-3: #f5f5f5;
$neutral-4: #e8e8e8;
$neutral-5: #d9d9d9;
$neutral-6: #bfbfbf;
$neutral-7: #8c8c8c;
$neutral-8: #595959;
$neutral-9: #262626;
$neutral-10: #000;

$neutrals: (
  'neutral-1': $neutral-1,
  'neutral-2': $neutral-2,
  'neutral-3': $neutral-3,
  'neutral-4': $neutral-4,
  'neutral-5': $neutral-5,
  'neutral-6': $neutral-6,
  'neutral-7': $neutral-7,
  'neutral-8': $neutral-8,
  'neutral-9': $neutral-9,
  'neutral-10': $neutral-10,
);

$blue-1: #e4eeff;
$blue-2: #c2d9ff;
$blue-3: #a0c3ff;
$blue-4: #7eaeff;
$blue-5: #4b8eff;
$blue-6: #2979ff;
$blue-7: #005cf5;
$blue-8: #0042b1;
$blue-9: #002f7e;
$blue-10: #001c4b;

$blues: (
  'blue-1': $blue-1,
  'blue-2': $blue-2,
  'blue-3': $blue-3,
  'blue-4': $blue-4,
  'blue-5': $blue-5,
  'blue-6': $blue-6,
  'blue-7': $blue-7,
  'blue-8': $blue-8,
  'blue-9': $blue-9,
  'blue-10': $blue-10,
);

$red-1: #fff4f6;
$red-2: #ffd2db;
$red-3: #ffb0bf;
$red-4: #ff7d96;
$red-5: #ff4a6d;
$red-6: #ff1744;
$red-7: #e3002c;
$red-8: #b00022;
$red-9: #8e001c;
$red-10: #6c0015;

$alt-red-1: #d98292;
$alt-red-2: #ca6878;
$alt-red-3: #cd5c5c;
$alt-red-4: #ef4352;
$alt-red-5: #e60026;
$alt-red-6: #ce2029;
$alt-red-7: #b31b1b;
$alt-red-8: #a91101;
$alt-red-9: #950901;
$alt-red-10: #800000;

$reds: (
  'red-1': $red-1,
  'red-2': $red-2,
  'red-3': $red-3,
  'red-4': $red-4,
  'red-5': $red-5,
  'red-6': $red-6,
  'red-7': $red-7,
  'red-8': $red-8,
  'red-9': $red-9,
  'red-10': $red-10,
);

$alt-reds: (
  'alt-red-1': $alt-red-1,
  'alt-red-2': $alt-red-2,
  'alt-red-3': $alt-red-3,
  'alt-red-4': $alt-red-4,
  'alt-red-5': $alt-red-5,
  'alt-red-6': $alt-red-6,
  'alt-red-7': $alt-red-7,
  'alt-red-8': $alt-red-8,
  'alt-red-9': $alt-red-9,
  'alt-red-10': $alt-red-10,
);

$orange-1: #fff5ee;
$orange-2: #ffe2cc;
$orange-3: #ffc599;
$orange-4: #ffa766;
$orange-5: #ff8a33;
$orange-6: #ff6d00;
$orange-7: #dd5e00;
$orange-8: #aa4900;
$orange-9: #730;
$orange-10: #552400;

$oranges: (
  'orange-1': $orange-1,
  'orange-2': $orange-2,
  'orange-3': $orange-3,
  'orange-4': $orange-4,
  'orange-5': $orange-5,
  'orange-6': $orange-6,
  'orange-7': $orange-7,
  'orange-8': $orange-8,
  'orange-9': $orange-9,
  'orange-10': $orange-10,
);

$yellow-1: #fffbee;
$yellow-2: #fff3cc;
$yellow-3: #ffebaa;
$yellow-4: #ffe077;
$yellow-5: #ffd033;
$yellow-6: #ffc400;
$yellow-7: #cc9d00;
$yellow-8: #997600;
$yellow-9: #664f00;
$yellow-10: #443400;

$yellows: (
  'yellow-1': $yellow-1,
  'yellow-2': $yellow-2,
  'yellow-3': $yellow-3,
  'yellow-4': $yellow-4,
  'yellow-5': $yellow-5,
  'yellow-6': $yellow-6,
  'yellow-7': $yellow-7,
  'yellow-8': $yellow-8,
  'yellow-9': $yellow-9,
  'yellow-10': $yellow-10,
);

$green-1: #e6fff3;
$green-2: #d5ffea;
$green-3: #c4ffe2;
$green-4: #91ffc9;
$green-5: #2bff98;
$green-6: #00e676;
$green-7: #00b35c;
$green-8: #00914a;
$green-9: #005e30;
$green-10: #003c1f;

$greens: (
  'green-1': $green-1,
  'green-2': $green-2,
  'green-3': $green-3,
  'green-4': $green-4,
  'green-5': $green-5,
  'green-6': $green-6,
  'green-7': $green-7,
  'green-8': $green-8,
  'green-9': $green-9,
  'green-10': $green-10,
);

$gray-1: #e6ecf0;
$gray-2: #c6d4dc;
$gray-3: #a7bcc9;
$gray-4: #7d9daf;
$gray-5: #587b90;
$gray-6: #4b697b;
$gray-7: #384e5b;
$gray-8: #2b3c46;
$gray-9: #1e2a31;
$gray-10: #121a1f;

$gray: (
  'gray-1': $gray-1,
  'gray-2': $gray-2,
  'gray-3': $gray-3,
  'gray-4': $gray-4,
  'gray-5': $gray-5,
  'gray-6': $gray-6,
  'gray-7': $gray-7,
  'gray-8': $gray-8,
  'gray-9': $gray-9,
  'gray-10': $gray-10,
);

// New desgin System Colors
$B40: #aecafb;
$B60: #85aff8;
$B100: #347af4;
$B300: #2161cc;
$B500: #194999;
$B800: #203d6a;
$B900: #243550;

$vfi-blue: (
  'b40': $B40,
  'b60': $B60,
  'b100': $B100,
  'b300': $B300,
  'b500': $B500,
);

$LM50: #c7ccd5;

$vfi-light-mode: (
  'lm50': $LM50,
);

$BORDER3: rgba(174, 202, 251, 0.24);
$vfi-border: (
  'border3': $BORDER3,
);

$DM00: #eceef1;
$DM10: #d9dde3;
$DM20: #c7ced6;
$DM30: #b5bec9;
$DM40: #a3aebd;
$DM50: #929fb0;
$DM60: #8392a5;
$DM100: #515f70;
$DM200: #475262;
$DM300: #3f4855;
$DM400: #373f49;
$DM500: #2f353d;
$DM600: #262b31;
$DM700: #202328;
$DM800: #17191c;
$DM900: #101213;

$vfi-dark-mode: (
  'dm00': $DM00,
  'dm10': $DM10,
  'dm20': $DM20,
  'dm30': $DM30,
  'dm40': $DM40,
  'dm50': $DM50,
  'dm60': $DM60,
  'dm100': $DM100,
  'dm200': $DM200,
  'dm300': $DM300,
  'dm500': $DM500,
  'dm600': $DM600,
  'dm800': $DM800,
  'dm900': $DM900,
);

$G80: #5cca9c;
$G300: #2c9b6c;

$vfi-green: (
  'g80': $G80,
  'g300': $G300,
);

$R6: #ff1744;
$R80: #de6363;
$R100: #d63c3c;
$R300: #af201f;
$R500: #841817;
$R700: #5f0212;

$vfi-red: (
  'r6': $R6,
  'r80': $R80,
  'r100': $R100,
  'r300': $R300,
  'r500': $R500,
  'r700': $R700,
);

$Y70: #f6cf50;
$Y100: #f2c530;
$Y500: #5f4011;

$vfi-yellow: (
  'y70': $Y70,
  'y100': $Y100,
  'y500': $Y500,
);

$N900: #142b52;

$vfi-navy: (
  'n900': $N900,
);

$priority-4-font: #612500;
$priority-3-font: #613400;

// Fonts
$regular-4: 4px;
$regular-10: 10px;
$regular-11: 11px;
$regular-12: 12px;
$regular-14: 14px;
$regular-16: 16px;
$regular-18: 18px;
$regular-20: 20px;
$regular-22: 22px;
$regular-24: 24px;
$regular-28: 28px;
$regular-30: 30px;
$regular-32: 32px;
$regular-38: 38px;
$regular-46: 46px;
$regular-54: 54px;
$regular-56: 56px;
$regular-64: 64px;
$regular-144: 144px;

$regulars: (
  'regular-4': $regular-4,
  'regular-10': $regular-10,
  'regular-11': $regular-11,
  'regular-12': $regular-12,
  'regular-14': $regular-14,
  'regular-16': $regular-16,
  'regular-18': $regular-18,
  'regular-20': $regular-20,
  'regular-22': $regular-22,
  'regular-24': $regular-24,
  'regular-30': $regular-30,
  'regular-32': $regular-32,
  'regular-38': $regular-38,
  'regular-46': $regular-46,
  'regular-56': $regular-56,
  'regular-64': $regular-64,
  'regular-144': $regular-144,
);

// Font weight
$font-weight-normal: 'normal';
$font-weight-bold: 'bold';
$font-weight-100: 100;
$font-weight-200: 200;
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-800: 800;
$font-weight-900: 900;

$font-weights: (
  'normal': $font-weight-normal,
  'bold': $font-weight-bold,
  '100': $font-weight-100,
  '200': $font-weight-200,
  '300': $font-weight-300,
  '400': $font-weight-400,
  '500': $font-weight-500,
  '600': $font-weight-600,
  '700': $font-weight-700,
  '800': $font-weight-800,
  '900': $font-weight-900,
);

// Line Height
$line-heights: (
  '20': $regular-20,
  '22': $regular-22,
  '24': $regular-24,
  '28': $regular-28,
  '30': $regular-30,
  '32': $regular-32,
  '38': $regular-38,
  '46': $regular-46,
  '54': $regular-54,
  '64': $regular-64,
  '144': $regular-144,
);

// Visibility
$visibility: (visible, hidden, collapse, initial, inherit);

// Position
$position: (static relative fixed absolute sticky);

// Height & Width
$sizes: (
  0: 0%,
  10: 10%,
  15: 15%,
  20: 20%,
  25: 25%,
  30: 30%,
  33: 33.333%,
  35: 35%,
  40: 40%,
  45: 45%,
  50: 50%,
  55: 55%,
  60: 60%,
  65: 65%,
  70: 70%,
  75: 75%,
  80: 80%,
  85: 85%,
  90: 90%,
  95: 95%,
  100: 100%,
);

// Ellipsis lines
$lines: (
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
);

// Borders
$borders: (
  'border-1': $gray-1,
  'border-2': $gray-2,
  'border-3': $gray-3,
  'border-4': $gray-4,
  'border-5': $gray-5,
  'border-6': $gray-6,
  'border-7': $gray-7,
  'border-8': $gray-8,
  'border-9': $gray-9,
);

$borders-errors: (
  'border-error-1': $red-1,
  'border-error-2': $red-2,
  'border-error-3': $red-3,
  'border-error-4': $red-4,
  'border-error-5': $red-5,
  'border-error-6': $red-6,
  'border-error-7': $red-7,
  'border-error-8': $red-8,
  'border-error-9': $red-9,
);

$white: #fff;
$gray-100: #f9f9f9;
$gray-200: #edeff0;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #b4bfc3;
$gray-600: #3d4b53;
$gray-700: #2b3942;
$gray-800: #22313a;
$gray-900: #1e2a31;
$black: #000;

$gray01: #18232a;
$gray02: #455a64;
$gray03: #def0f8;
$gray04: #dfe1e2;

$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map.merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
  ),
  $grays
);

$blue: #2979ff;
$indigo: #6610f2 !default;
$purple: #d500f9;
$pink: #e83e8c !default;
$red: #ff1744;
$orange: #ff6d00;
$yellow: #ffc400;
$green: #00e676;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$blue-light: #00b0ff;

$colors: ();
$colors: map.merge(
  (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'white': $white,
    'gray': $gray-600,
    'gray-dark': $gray-800,
  ),
  $colors
);

$primary: $blue-light;
$secondary: $gray-600;
$success: $green;
$info: $purple;
$warning: $yellow;
$danger: $red;
$light: $gray-500;
$dark: $gray01;

$theme-colors: ();
$theme-colors: map.merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
  ),
  $theme-colors
);

$spacer: 1rem;
$spacers: ();
$spacers: map.merge(
  (
    0: 0,
    1: (
      $spacer * 0.375,
    ),
    2: (
      $spacer * 0.75,
    ),
    3: (
      $spacer * 0.9375,
    ),
    4: (
      $spacer * 1.25,
    ),
    5: (
      $spacer * 1.5625,
    ),
    6: (
      $spacer * 1.875,
    ),
    7: (
      $spacer * 2.1875,
    ),
    8: (
      $spacer * 2.5,
    ),
    9: (
      $spacer * 9,
    ),
    10: (
      $spacer * 10.5,
    ),
  ),
  $spacers
);

$sides: (
  '': '',
  't': 'top',
  'b': 'bottom',
  'l': 'left',
  'r': 'right',
);

$grid-breakpoints: ();
$grid-breakpoints: map.merge(
  (
    '': '',
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    tv: 1400px,
  ),
  $grid-breakpoints
);

// This is the default html and body font-size for the base rem value.
$rem-base: 16px;

$line-height-base: 1.5 !default;

// Body
$base-min-width: 320px;

$body-bg: $gray-9;
$body-color: $gray-500;

// Retrieve color Sass maps
@function color($key: 'blue') {
  @return map.get($colors, $key);
}

@function theme-color($key: 'primary') {
  @return map.get($theme-colors, $key);
}

@function gray($key: '100') {
  @return map.get($grays, $key);
}

// Links

$link-color: theme-color('primary');
$link-decoration: none;
$link-hover-color: $white;
$link-hover-decoration: none;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;

// Paragraphs

$paragraph-margin-bottom: 0.375rem;

$component-active-color: $white;
$component-active-bg: theme-color('primary');

$caret-width: 0.3em;

$transition-base: all 0.2s ease-in-out;
$transition-fade: opacity 0.15s linear;
$transition-collapse: height 0.35s ease;

// Fonts
$font-family-sans-serif: 'Roboto', Arial, 'Noto Sans', sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  'Liberation Mono', 'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif;

$font-standard-size: '14.4px';

$fa-font-path: '~@fortawesome/fontawesome-pro/webfonts';
