@import 'node_modules/ng-zorro-antd/ng-zorro-antd.less';
// The prefix to use on all css classes from ant.
@ant-prefix: ant;

// -------- Colors -----------

// Color system
@neutral-1: #fff;
@neutral-2: #fafafa;
@neutral-3: #f5f5f5;
@neutral-4: #e8e8e8;
@neutral-5: #d9d9d9;
@neutral-6: #bfbfbf;
@neutral-7: #8c8c8c;
@neutral-8: #595959;
@neutral-9: #262626;
@neutral-10: #000;

@blue-1: #e4eeff;
@blue-2: #c2d9ff;
@blue-3: #a0c3ff;
@blue-4: #7eaeff;
@blue-5: #4b8eff;
@blue-6: #2979ff;
@blue-7: #005cf5;
@blue-8: #0042b1;
@blue-9: #002f7e;
@blue-10: #001c4b;

@red-1: #fff4f6;
@red-2: #ffd2db;
@red-3: #ffb0bf;
@red-4: #ff7d96;
@red-5: #ff4a6d;
@red-6: #ff1744;
@red-7: #e3002c;
@red-8: #b00022;
@red-9: #8e001c;
@red-10: #6c0015;

@orange-1: #fff5ee;
@orange-2: #ffe2cc;
@orange-3: #ffc599;
@orange-4: #ffa766;
@orange-5: #ff8a33;
@orange-6: #ff6d00;
@orange-7: #dd5e00;
@orange-8: #aa4900;
@orange-9: #773300;
@orange-10: #552400;

@yellow-1: #fffbee;
@yellow-2: #fff3cc;
@yellow-3: #ffebaa;
@yellow-4: #ffe077;
@yellow-5: #ffd033;
@yellow-6: #ffc400;
@yellow-7: #cc9d00;
@yellow-8: #997600;
@yellow-9: #664f00;
@yellow-10: #443400;

@green-1: #e6fff3;
@green-2: #d5ffea;
@green-3: #c4ffe2;
@green-4: #91ffc9;
@green-5: #2bff98;
@green-6: #00e676;
@green-7: #00b35c;
@green-8: #00914a;
@green-9: #005e30;
@green-10: #003c1f;

@gray-1: #e6ecf0;
@gray-2: #c6d4dc;
@gray-3: #a7bcc9;
@gray-4: #7d9daf;
@gray-5: #587b90;
@gray-6: #4b697b;
@gray-7: #384e5b;
@gray-8: #2b3c46;
@gray-9: #1e2a31;
@gray-10: #121a1f;

// Fonts
@regular-12: {
  font-size: 12px;
  line-height: 20px;
};

@regular-14: {
  font-size: 14px;
  line-height: 22px;
};

@regular-16: {
  font-size: 16px;
  line-height: 24px;
};

@regular-18: {
  font-size: 18px;
  line-height: 26px;
};

@regular-20: {
  font-size: 20px;
  line-height: 28px;
};

@regular-24: {
  font-size: 24px;
  line-height: 32px;
};

@regular-30: {
  font-size: 30px;
  line-height: 38px;
};

@regular-38: {
  font-size: 38px;
  line-height: 46px;
};

@regular-46: {
  font-size: 46px;
  line-height: 54px;
};

@regular-56: {
  font-size: 56px;
  line-height: 64px;
};

@white: #fff;
@gray-100: #f9f9f9;
@gray-200: #edeff0;
@gray-300: #dee2e6;
@gray-400: #ced4da;
@gray-500: #b4bfc3;
@gray-600: #3d4b53;
@gray-700: #2b3942;
@gray-800: #22313a;
@gray-900: #1e2a31;
@black: #000;

@gray01: #18232a;
@gray02: #455a64;
@gray03: #8a99a0;
@gray04: #dfe1e2;

@blue: #2979ff;
@indigo: #6610f2;
@purple: #d500f9;
@pink: #e83e8c;
@red: #ff1744;
@orange: #ff6d00;
@yellow: #ffc400;
@green: #00e676;
@teal: #20c997;
@cyan: #17a2b8;

@blue-light: #00b0ff;

@primary-color: @blue-6;
@info-color: @blue-6;
@success-color: @green-6;
@processing-color: @blue;
@error-color: @red-6;
@highlight-color: @red;
@warning-color: @yellow-6;
@normal-color: @gray01;

// Color used by default to control hover and active backgrounds and for
// alert info backgrounds.
@primary-1: color(
  colorPalette('@{primary-color}', 1)
); // replace tint(@primary-color, 90%)
@primary-2: color(
  colorPalette('@{primary-color}', 2)
); // replace tint(@primary-color, 80%)
@primary-3: color(colorPalette('@{primary-color}', 3)); // unused
@primary-4: color(colorPalette('@{primary-color}', 4)); // unused
@primary-5: color(
  colorPalette('@{primary-color}', 5)
); // color used to control the text color in many active and hover states, replace tint(@primary-color, 20%)
@primary-6: @primary-color; // color used to control the text color of active buttons, don't use, use @primary-color
@primary-7: color(
  colorPalette('@{primary-color}', 7)
); // replace shade(@primary-color, 5%)
@primary-8: color(colorPalette('@{primary-color}', 8)); // unused
@primary-9: color(colorPalette('@{primary-color}', 9)); // unused
@primary-10: color(colorPalette('@{primary-color}', 10)); // unused

// Base Scaffolding Variables
// ---

// Background color for `<body>`
@body-background: @gray01;
// Base background color for most components
@component-background: @gray-700;
@font-family: 'Roboto', Arial, 'Noto Sans', sans-serif;
@code-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
  monospace;
@heading-color: @white;
@text-color: @gray-500;
@text-color-secondary: @gray-600;
@heading-color-dark: fade(#fff, 100%);
@text-color-dark: fade(#fff, 85%);
@text-color-secondary-dark: fade(#fff, 65%);
@font-variant-base: tabular-nums;
@font-size-base: 14px;
@font-size-lg: 16px;
@font-size-sm: 14px;
@line-height-base: 1.5;
@border-radius-base: 4px;
@border-radius-sm: 2px;

// vertical paddings
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items

// vertical padding for all form controls
@control-padding-horizontal: @padding-sm;
@control-padding-horizontal-sm: @padding-xs;

// The background colors for active and hover states for things like
// list items or table cells.
@item-active-bg: @primary-1;
@item-hover-bg: rgba(@white, 0.1);

// ICONFONT
@iconfont-css-prefix: anticon;

// LINK
@link-color: @white;
@link-hover-color: @white;
@link-active-color: @blue-4;
@link-decoration: none;
@link-hover-decoration: underline;

// Animation
@ease-base-out: cubic-bezier(0.7, 0.3, 0.1, 1);
@ease-base-in: cubic-bezier(0.9, 0, 0.3, 0.7);
@ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
@ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
@ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
@ease-out-back: cubic-bezier(0.12, 0.4, 0.29, 1.46);
@ease-in-back: cubic-bezier(0.71, -0.46, 0.88, 0.6);
@ease-in-out-back: cubic-bezier(0.71, -0.46, 0.29, 1.46);
@ease-out-circ: cubic-bezier(0.08, 0.82, 0.17, 1);
@ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.34);
@ease-in-out-circ: cubic-bezier(0.78, 0.14, 0.15, 0.86);
@ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
@ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
@ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);

// Border color
@border-color-base: @gray-900;
@border-color-split: @gray-900; // split border inside a component
@border-width-base: 1px; // width of the border for a component
@border-style-base: solid; // style of a components border

// Outline
@outline-blur-size: 0;
@outline-width: 0px;

@background-color-light: hsv(
  0,
  0,
  98%
); // background of header and selected item
@background-color-base: hsv(0, 0, 96%); // Default grey background color

// Disabled states
@disabled-color: fade(#000, 25%);
@disabled-bg: @background-color-base;
@disabled-color-dark: fade(#fff, 35%);

// Shadow
@shadow-color: rgba(0, 0, 0, 0.15);
@box-shadow-base: @shadow-1-down;
@shadow-1-up: 0 -2px 8px @shadow-color;
@shadow-1-down: 0 2px 8px @shadow-color;
@shadow-1-left: -2px 0 8px @shadow-color;
@shadow-1-right: 2px 0 8px @shadow-color;
@shadow-2: 0 4px 12px @shadow-color;

// Buttons
@btn-font-weight: 400;
@btn-border-radius-base: @border-radius-base;
@btn-border-radius-sm: @border-radius-base;

@btn-primary-color: @white;
@btn-primary-bg: @primary-color;

@btn-default-color: @white;
@btn-default-bg: @gray-700;
@btn-default-border: @gray-700;

@btn-danger-color: @neutral-1;
@btn-danger-bg: @red-6;
@btn-danger-border: @red-3;

@btn-disable-color: @white;
@btn-disable-bg: @primary-color;
@btn-disable-border: @primary-color;

@btn-padding-base: 0 @padding-md - 1px;
@btn-font-size-lg: @font-size-lg;
@btn-font-size-sm: @font-size-base;
@btn-padding-lg: @btn-padding-base;
@btn-padding-sm: 0 @padding-xs - 1px;

@btn-height-base: 32px;
@btn-height-lg: 40px;
@btn-height-sm: 24px;

@btn-circle-size: @btn-height-base;
@btn-circle-size-lg: @btn-height-lg;
@btn-circle-size-sm: @btn-height-sm;

@btn-group-border: @primary-5;

// Checkbox
@checkbox-size: 16px;
@checkbox-color: #a7bcc9;
@checkbox-check-color: @gray-900;
@checkbox-border-width: @border-width-base;

// Radio
@radio-size: 16px;
@radio-dot-color: @primary-color;

// Radio buttons
@radio-button-bg: @btn-default-bg;
@radio-button-color: @btn-default-color;
@radio-button-hover-color: @primary-5;
@radio-button-active-color: @primary-7;

// Media queries breakpoints
// Extra small screen / phone
@screen-xs: 480px;
@screen-xs-min: @screen-xs;

// Small screen / tablet
@screen-sm: 576px;
@screen-sm-min: @screen-sm;

// Medium screen / desktop
@screen-md: 768px;
@screen-md-min: @screen-md;

// Large screen / wide desktop
@screen-lg: 992px;
@screen-lg-min: @screen-lg;

// Extra large screen / full hd
@screen-xl: 1200px;
@screen-xl-min: @screen-xl;

// Extra extra large screen / large descktop
@screen-xxl: 1400px;
@screen-xxl-min: @screen-xxl;

// provide a maximum
@screen-xs-max: (@screen-sm-min - 1px);
@screen-sm-max: (@screen-md-min - 1px);
@screen-md-max: (@screen-lg-min - 1px);
@screen-lg-max: (@screen-xl-min - 1px);
@screen-xl-max: (@screen-xxl-min - 1px);

// Grid system
@grid-columns: 24;
@grid-gutter-width: 0;

// Layout
@layout-body-background: #f0f2f5;
@layout-header-background: #001529;
@layout-footer-background: @layout-body-background;
@layout-header-height: 64px;
@layout-header-padding: 0 50px;
@layout-footer-padding: 24px 50px;
@layout-sider-background: @layout-header-background;
@layout-trigger-height: 48px;
@layout-trigger-background: #002140;
@layout-trigger-color: #fff;
@layout-zero-trigger-width: 36px;
@layout-zero-trigger-height: 42px;
// Layout light theme
@layout-sider-background-light: #fff;
@layout-trigger-background-light: #fff;
@layout-trigger-color-light: @text-color;

// z-index list
@zindex-affix: 10;
@zindex-back-top: 10;
@zindex-modal-mask: 1000;
@zindex-modal: 1000;
@zindex-notification: 1010;
@zindex-message: 1010;
@zindex-popover: 1030;
@zindex-picker: 1050;
@zindex-dropdown: 1050;
@zindex-tooltip: 1060;

// Animation
@animation-duration-slow: 0.3s; // Modal
@animation-duration-base: 0.2s;
@animation-duration-fast: 0.1s; // Tooltip

// Form
// ---
@label-required-color: @highlight-color;
@label-color: @heading-color;
@form-item-margin-bottom: 24px;
@form-item-trailing-colon: true;
@form-vertical-label-padding: 0 0 8px;
@form-vertical-label-margin: 0;

// Input
// ---
@input-height-base: 32px;
@input-height-lg: 50px;
@input-height-sm: 24px;
@input-padding-horizontal: @control-padding-horizontal - 1px;
@input-padding-horizontal-base: @input-padding-horizontal;
@input-padding-horizontal-sm: @control-padding-horizontal-sm - 1px;
@input-padding-horizontal-lg: @input-padding-horizontal;
@input-padding-vertical-base: 4px;
@input-padding-vertical-sm: 1px;
@input-padding-vertical-lg: 6px;
@input-placeholder-color: hsv(0, 0, 75%);
@input-color: @gray-200;
@input-border-color: @gray-10;
@input-bg: @gray-10;
@input-addon-bg: @background-color-light;
// @input-hover-border-color : @primary-color;
@input-disabled-bg: @gray-8;
@input-outline-offset: 0 0;

// Tooltip
// ---
//* Tooltip max width
@tooltip-max-width: 250px;
//** Tooltip text color
@tooltip-color: #fff;
//** Tooltip background color
@tooltip-bg: #384e5b;
//** Tooltip arrow width
@tooltip-arrow-width: 5px;
//** Tooltip distance with trigger
@tooltip-distance: @tooltip-arrow-width - 1px + 4px;
//** Tooltip arrow color
@tooltip-arrow-color: @tooltip-bg;

// Popover
// ---
//** Popover body background color
@popover-bg: @gray-7;
//** Popover text color
@popover-color: @text-color;
//** Popover maximum width
@popover-min-width: 177px;
//** Popover arrow width
@popover-arrow-width: 6px;
//** Popover arrow color
@popover-arrow-color: @popover-bg;
//** Popover outer arrow width
//** Popover outer arrow color
@popover-arrow-outer-color: @popover-bg;
//** Popover distance with trigger
@popover-distance: @popover-arrow-width + 4px;

// Modal
// --
@modal-header-bg: @gray-7;
@modal-mask-bg: rgba(0, 0, 0, 0.65);

// Progress
// --
@progress-default-color: @processing-color;
@progress-remaining-color: @background-color-base;
@progress-text-color: @text-color;

// Menu
// ---
@menu-inline-toplevel-item-height: 40px;
@menu-item-height: 40px;
@menu-collapsed-width: 80px;
@menu-bg: @gray-8;
@menu-popup-bg: @component-background;
@menu-item-color: @white;
@menu-highlight-color: @white;
@menu-item-active-bg: @gray-900;
@menu-item-active-border-width: 0px;
@menu-item-group-title-color: @text-color-secondary;
// dark theme
@menu-dark-color: @text-color-secondary-dark;
@menu-dark-bg: @gray-700;
@menu-dark-arrow-color: @white;
@menu-dark-submenu-bg: @gray-8;
@menu-dark-highlight-color: @white;
@menu-dark-item-active-bg: @gray-900;

// Spin
// ---
@spin-dot-size-sm: 14px;
@spin-dot-size: 20px;
@spin-dot-size-lg: 32px;

// Table
// --
@table-header-bg: @background-color-light;
@table-header-color: @neutral-1;
@table-header-sort-bg: @background-color-base;
@table-body-sort-bg: rgba(0, 0, 0, 0.01);
@table-row-hover-bg: @gray-7;
@table-selected-row-bg: #fafafa;
@table-expanded-row-bg: #fbfbfb;
@table-padding-vertical: 16px;
@table-padding-horizontal: 16px;

// Tag
// --
@tag-default-bg: @background-color-light;
@tag-default-color: @text-color;
@tag-font-size: @font-size-sm;

// TimePicker
// ---
@time-picker-panel-column-width: 56px;
@time-picker-panel-width: @time-picker-panel-column-width * 3;
@time-picker-selected-bg: @gray-10;

// Carousel
// ---
@carousel-dot-width: 16px;
@carousel-dot-height: 3px;
@carousel-dot-active-width: 24px;

// Badge
// ---
@badge-height: 20px;
@badge-dot-size: 6px;
@badge-font-size: @font-size-sm;
@badge-font-weight: normal;
@badge-status-size: 6px;

// Rate
// ---
@rate-star-color: @yellow-6;
@rate-star-bg: @border-color-split;

// Card
// ---
@card-head-color: @heading-color;
@card-head-background: transparent;
@card-head-padding: 16px;
@card-inner-head-padding: 12px;
@card-padding-base: 24px;
@card-padding-wider: 32px;
@card-actions-background: @background-color-light;
@card-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);

// Comment
// ---
@comment-padding-base: 16px 0;
@comment-nest-indent: 44px;
@comment-author-name-color: @text-color-secondary;
@comment-author-time-color: #ccc;
@comment-action-color: @text-color-secondary;
@comment-action-hover-color: #595959;

// Tabs
// ---
@tabs-card-head-background: @background-color-light;
@tabs-card-height: 40px;
@tabs-card-active-color: @neutral-1;
@tabs-title-font-size: @font-size-base;
@tabs-title-font-size-lg: @font-size-lg;
@tabs-title-font-size-sm: @font-size-base;
@tabs-ink-bar-color: @neutral-1;
@tabs-bar-margin: 0 0 16px 0;
@tabs-horizontal-margin: 0 32px 0 0;
@tabs-horizontal-padding: 12px 16px;
@tabs-vertical-padding: 8px 24px;
@tabs-vertical-margin: 0 0 16px 0;
@tabs-scrolling-size: 32px;
@tabs-highlight-color: @neutral-1;
@tabs-hover-color: @neutral-2;
@tabs-active-color: @neutral-5;

// BackTop
// ---
@back-top-color: #fff;
@back-top-bg: @text-color-secondary;
@back-top-hover-bg: @text-color;

// Avatar
// ---
@avatar-size-base: 32px;
@avatar-size-lg: 40px;
@avatar-size-sm: 24px;
@avatar-font-size-base: 18px;
@avatar-font-size-lg: 24px;
@avatar-font-size-sm: 14px;
@avatar-bg: @gray-9;
@avatar-color: @neutral-1;
@avatar-border-radius: @border-radius-base;

// Switch
// ---
@switch-height: 19px;
@switch-sm-height: 16px;
@switch-sm-checked-margin-left: -(@switch-sm-height - 3px);
@switch-disabled-opacity: 0.4;
@switch-color: @green;

// Pagination
// ---
@pagination-item-size: 32px;
@pagination-item-size-sm: 24px;
@pagination-font-family: Arial;
@pagination-font-weight-active: 500;

// Breadcrumb
// ---
@breadcrumb-base-color: @text-color-secondary;
@breadcrumb-last-item-color: @text-color;
@breadcrumb-font-size: @font-size-base;
@breadcrumb-icon-font-size: @font-size-base;
@breadcrumb-link-color: @text-color-secondary;
@breadcrumb-link-color-hover: @primary-5;
@breadcrumb-separator-color: @text-color-secondary;
@breadcrumb-separator-margin: 0 @padding-xs;

// Slider
// ---
@slider-margin: 14px 6px 10px;
@slider-rail-background-color: @background-color-base;
@slider-rail-background-color-hover: #e1e1e1;
@slider-track-background-color: @primary-5;
@slider-track-background-color-hover: @primary-6;
@slider-handle-color: @primary-5;
@slider-handle-color-hover: @primary-6;
@slider-handle-color-focus: tint(@primary-color, 20%);
@slider-handle-color-focus-shadow: fade(@primary-color, 20%);
@slider-handle-color-tooltip-open: @primary-color;
@slider-dot-border-color: @border-color-split;
@slider-dot-border-color-active: @primary-5;
@slider-disabled-color: @disabled-color;
@slider-disabled-background-color: @component-background;

// Tree
// ---
@tree-title-height: 24px;
@tree-child-padding: 18px;
@tree-directory-selected-color: #fff;
@tree-directory-selected-bg: @primary-color;

// Collapse
// ---
@collapse-header-padding: 12px 16px 12px 40px;
@collapse-header-bg: @gray-9;
@collapse-content-padding: 15px 0px 0px 0px;
@collapse-content-bg: @gray-8;

// Skeleton
// ---
@skeleton-color: @gray-600;

// Transfer
// ---
@transfer-disabled-bg: @disabled-bg;

// Message
// ---
@message-notice-content-padding: 10px 16px;

// Motion
// ---
@wave-animation-width: 6px;

// Alert
// ---
@alert-success-border-color: colorPalette('@{success-color}', 3);
@alert-success-bg-color: colorPalette('@{success-color}', 1);
@alert-success-icon-color: @success-color;
@alert-info-border-color: colorPalette('@{info-color}', 3);
@alert-info-bg-color: colorPalette('@{info-color}', 1);
@alert-info-icon-color: @info-color;
@alert-warning-border-color: colorPalette('@{warning-color}', 3);
@alert-warning-bg-color: colorPalette('@{warning-color}', 1);
@alert-warning-icon-color: @warning-color;
@alert-error-border-color: colorPalette('@{error-color}', 3);
@alert-error-bg-color: colorPalette('@{error-color}', 1);
@alert-error-icon-color: @error-color;

// List
// ---
@list-empty-text-padding: @padding-md;
@list-item-padding: @padding-sm 0;
@list-item-content-margin: 0 0 @padding-md 0;
@list-item-meta-margin-bottom: @padding-md;
@list-item-meta-avatar-margin-right: @padding-md;
@list-item-meta-title-margin-bottom: @padding-sm;
