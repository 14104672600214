@use "../base/mixins";
@use "../vars";
@use "../vendors/bootstrap/mixins/breakpoints";

/* footer styles */
#footer {
  height: 60px;
  @include mixins.extend-bg(vars.$body-bg);

  @include breakpoints.media-breakpoint-up(md) {
    height: 70px;
  }
  /* footer logo styles */
  .logo {
    margin: 0 7px 0 0;
    width: 110px;

    @include breakpoints.media-breakpoint-up(md) {
      margin: 0 15px 0 0;
      width: 130px;
    }

    @include breakpoints.media-breakpoint-up(xl) {
      margin: 0 30px 0 0;
      width: 137px;
    }
  }
}
/* footer navigation styles */
.fnav {
  margin: 0 -2px;

  @include breakpoints.media-breakpoint-up(md) {
    margin: 0 -4px;
  }

  @include breakpoints.media-breakpoint-up(lg) {
    margin: 0 -6px;
  }

  &:last-child {
    flex-grow: 1;
    justify-content: flex-end;

    @include breakpoints.media-breakpoint-up(md) {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  li {
    padding: 2px;

    @include breakpoints.media-breakpoint-up(md) {
      padding: 4px;
    }

    @include breakpoints.media-breakpoint-up(lg) {
      padding: 5px 6px;
    }

    &.active {
      a {
        background: vars.$gray-900;
        border: 1px solid vars.$white;
        color: vars.$white;
      }
    }

    a {
      @include mixins.size(30px);
      border-radius: 100%;
      display: block;
      overflow: hidden;

      @include breakpoints.media-breakpoint-up(md) {
        @include mixins.size(36px);
      }

      img {
        @include mixins.responsive-image;
      }
    }
  }
}
/* comment form styles */
.comment-form-holder {
  bottom: 0;
  left: -12px;
  position: absolute;
  right: -12px;
  z-index: 999;

  @include breakpoints.media-breakpoint-up(md) {
    padding: 0 15px;
    position: static;
  }

  @include breakpoints.media-breakpoint-up(lg) {
    padding: 0 20px;
  }

  .btn-close {
    right: 15px;
  }

  .comment-form {
    background: vars.$body-bg;
    box-shadow: 0 0 15px rgba(vars.$gray-200, 0.1);
    margin: 0;
    padding: 10px 40px 10px 15px;

    @include breakpoints.media-breakpoint-up(md) {
      background: none;
      box-shadow: none;
      margin: 0 auto;
      max-width: 516px;
      padding: 0;
    }
  }
}

.comment-form {
  margin: 0 0 12px;

  label {
    @include mixins.size(24px);
    border-radius: 100%;
    margin: 0 6px 0 0;
    overflow: hidden;

    @include breakpoints.media-breakpoint-up(lg) {
      margin: 0 12px 0 0;
    }

    img {
      @include mixins.responsive-image;
    }
  }

  textarea.form-control {
    max-height: 80px;

    @include breakpoints.media-breakpoint-up(md) {
      max-height: 60px;
    }
  }
}
