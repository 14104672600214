@use 'sass:color';
@use '../base/mixins';
@use '../bootstrap-custom';
@use '../vars';
@use '../vendors/bootstrap/mixins/breakpoints';

/*  ---------------------------------------------------------
3. Сommon site styles
---------------------------------------------------------*/
/* main informative part styles */

h3 {
  color: #fff;
  font-family: inherit;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: inherit;
  margin-bottom: 13px;
  text-transform: uppercase;
}

.looker-iframe {
  width: 100%;
  height: calc(100vh - 203px);
  border: transparent;
}

#main {
  height: calc(100% - 120px);
  position: relative;

  @include breakpoints.media-breakpoint-up(md) {
    height: calc(100% - 148px);
    padding: 0 0 0 50px;
  }

  @include breakpoints.media-breakpoint-up(lg) {
    height: calc(100% - 178px);
    padding: 0;
  }
}

.main-holder {
  background: vars.$gray-900;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.main-frame {
  height: 100%;
}
/* content header styles */
.main-header {
  @include breakpoints.media-breakpoint-down(sm) {
    padding: 10px 0 5px 0;
    box-shadow: none;
  }

  align-items: center;
  box-shadow: 0 1px 0 vars.$gray-700;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 8px 10px;
  padding: 15px 0 12px 0;
  position: relative;
  z-index: 99;
  > [class*='icon-'] {
    color: vars.$white;
    left: 12px;
    position: absolute;
    top: 12px;

    @include breakpoints.media-breakpoint-up(sm) {
      position: static;
    }

    &:not(.square) {
      font-size: 18px;
      margin: 0 12px 0 0;
    }
  }

  .id {
    font-size: 20px;
    line-height: 1.2;
    margin: 0 10px 0 0;
  }

  h2 {
    margin: 0 10px 0 0;

    @include breakpoints.media-breakpoint-up(md) {
      margin: 0 7px 0 0;
    }
  }

  time {
    display: block;
  }

  .section-dropdown {
    margin: 0;
    position: absolute;
    right: 10px;
    top: 12px;

    @include breakpoints.media-breakpoint-up(sm) {
      top: 50%;
      transform: translateY(-50%);
    }

    @include breakpoints.media-breakpoint-up(lg) {
      right: 23px;
    }
  }

  .btn-close {
    right: 12px;

    @include breakpoints.media-breakpoint-up(lg) {
      right: 29px;
    }
  }
}

.content-wrapper {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}
/* main content styles */
#content {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
}

.section {
  padding: 15px 10px;

  @include breakpoints.media-breakpoint-up(md) {
    padding: 20px 12px 36px;
  }

  .header {
    margin: 0 0 13px;
    padding: 0 30px 0 0;
    position: relative;

    @include breakpoints.media-breakpoint-up(sm) {
      align-items: center;
      display: flex;
      flex-flow: row wrap;
    }

    @include breakpoints.media-breakpoint-up(md) {
      padding: 0 36px 0 0;
    }

    h2 {
      margin: 0 5px 0 0;

      @include breakpoints.media-breakpoint-up(md) {
        margin: 0 7px 0 2px;
      }
    }

    time {
      display: block;
      padding: 0 0 0 21px;

      @include breakpoints.media-breakpoint-up(sm) {
        padding: 0;
      }
    }

    .section-dropdown {
      margin: 0;
      position: absolute;
      right: 0;
      top: 3px;
    }
  }

  .subhead {
    align-items: center;
    display: flex;
    margin: 0 0 10px;

    h3 {
      flex-grow: 1;
      margin: 0 10px 0 0;
    }
  }
}
/* location form */
.location-form {
  margin: 0 0 16px;

  h3 {
    margin: 0 0 7px;
  }
}
/* graph box styles */
.graph-box {
  margin: 0 0 10px;
  overflow: hidden;

  @include breakpoints.media-breakpoint-up(md) {
    margin: 0 0 19px;
  }
}
/* graph placeholder styles */
.graph-holder {
  margin: 0 0 13px;
  position: relative;

  img {
    @include mixins.responsive-image;
  }
}
/* graph info list styles */
.graph-info {
  margin: 0 0 5px;
  text-transform: uppercase;

  &.d-flex {
    margin: 0 -5px 5px;

    @include breakpoints.media-breakpoint-up(md) {
      margin: 0 -10px 5px;
    }

    @include breakpoints.media-breakpoint-up(lg) {
      margin: 0 -18px 5px;
    }

    li {
      padding: 0 5px;

      @include breakpoints.media-breakpoint-up(md) {
        padding: 0 10px;
      }

      @include breakpoints.media-breakpoint-up(lg) {
        padding: 0 18px;
      }
    }
  }

  &.bullets {
    margin: 0;
    text-transform: capitalize;

    &.d-flex {
      flex-flow: row wrap;
      margin: 0 -16px -5px 0;

      li {
        padding: 0 16px 0 12px;

        &:before {
          top: 6px;
        }
      }
    }

    &.two-columns {
      display: flex;
      flex-flow: column wrap;
      height: 66px;
      justify-content: flex-start;
      width: 100%;

      @include breakpoints.media-breakpoint-up(md) {
        flex-direction: row;
        height: auto;
        width: auto;
      }

      @include breakpoints.media-breakpoint-up(lg) {
        flex-direction: column;
        height: 66px;
      }

      li {
        padding-right: 10px;
        width: 122px;
      }
    }

    li {
      padding: 2px 0 2px 12px;
      position: relative;

      &.red:before {
        background: vars.$danger;
      }

      &.yellow:before {
        background: vars.$warning;
      }

      &.light:before {
        background: vars.$gray03;
      }

      &.dark:before {
        background: vars.$gray02;
      }

      &:before {
        @include mixins.size(6px);
        @include mixins.pseudo-elements(8px, auto, auto, 0);
        background: vars.$orange;
        border-radius: 100%;
      }
    }

    .heading {
      @include breakpoints.media-breakpoint-up(xl) {
        margin: 0 15px 0 0;
      }

      &:last-child {
        margin: 0 0 0 5px;
      }
    }
  }

  .heading {
    margin: 0 5px 0 0;
    @include mixins.align(top);
    max-width: 150px;
    opacity: 0.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include breakpoints.media-breakpoint-up(md) {
      margin: 0 10px 0 0;
    }
  }

  .value {
    @include mixins.align(top);
  }
}
/* graph rating styles */
.graph-rating {
  flex-shrink: 0;
  margin: 0 0 0 5px;
  padding: 0 0 0 18px;
  position: relative;

  @include breakpoints.media-breakpoint-up(md) {
    margin: 0 0 0 10px;
    padding: 0 0 0 21px;
  }

  &:first-child {
    margin: 0 10px 0 0;

    @include breakpoints.media-breakpoint-up(md) {
      margin: 0 22px 0 0;
    }
  }

  &.up {
    &:before {
      content: '\e902';
    }
  }

  &:before {
    @include mixins.icon('\e903');
    color: vars.$gray-200;
    font-size: 12px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @include breakpoints.media-breakpoint-up(md) {
      font-size: 15px;
    }
  }

  .value {
    color: vars.$white;
    display: block;
    font-size: 20px;
    line-height: 1;

    @include breakpoints.media-breakpoint-up(md) {
      font-size: 25px;
    }

    @include breakpoints.media-breakpoint-up(xl) {
      font-size: 30px;
    }
  }

  .heading {
    display: block;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;

    @include breakpoints.media-breakpoint-up(md) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
/* vertical graph bars styles */
.vertical-graph-bars {
  margin: 0 0 6px;
  max-width: 400px;

  .progress {
    border-radius: 0;
    height: 15px;

    &-bar {
      border-radius: 0;
    }
  }
}
/* graph-bars styles */
.horizontal-graph-bars {
  display: inline-flex;
  flex-shrink: 0;
  height: 56px;
  margin: 0 5px 0 0;

  @include breakpoints.media-breakpoint-only(lg) {
    margin: 0 9px 0 0;
  }

  .progress {
    border-radius: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    overflow: visible;
    padding: 0 4px;
    width: 28px;

    @include breakpoints.media-breakpoint-only(lg) {
      padding: 0 3px;
      width: 24px;
    }

    .value {
      color: vars.$white;
      font-size: 12px;
      margin: -8px -4px 0;
      position: relative;
      text-align: center;
      white-space: nowrap;

      @include breakpoints.media-breakpoint-only(lg) {
        font-size: 10px;
        margin: -4px -4px 0;
      }

      [class*='icon-'] {
        font-size: 8px;
        line-height: inherit;
        margin: 0 3px 0 0;

        @include breakpoints.media-breakpoint-only(lg) {
          margin: 0 2px 0 0;
        }
      }
    }

    &-bar {
      border-radius: bootstrap-custom.$border-radius;
      width: 100%;

      &-bg-light {
        background: vars.$gray02 !important;
      }
    }
  }
}
/* progress holder styles */
.progress-holder {
  margin: 0 0 4px;

  img {
    @include mixins.responsive-image;
  }
}
/* conversation styles */
.conversation {
  .coversation-box {
    position: relative;
  }

  .message {
    align-items: flex-start;
    display: flex;
    margin: 0 0 12px;

    &.odd {
      flex-flow: row-reverse;

      .author {
        margin-left: 7px;
        margin-right: 0;

        @include breakpoints.media-breakpoint-up(md) {
          margin-left: 12px;
        }
      }
    }
  }

  .author {
    @include mixins.size(24px);
    border-radius: 100%;
    flex-shrink: 0;
    margin: 2px 7px 0 0;
    overflow: hidden;

    @include breakpoints.media-breakpoint-up(md) {
      margin: 2px 12px 0 0;
    }

    img {
      @include mixins.responsive-image;
    }
  }

  .card {
    flex-grow: 1;
    padding: 8px;

    @include breakpoints.media-breakpoint-up(md) {
      letter-spacing: 0.5px;
      padding: 10px 12px 12px;
    }

    &.small-box {
      max-width: 322px;

      p {
        margin: 0 0 10px;

        @include breakpoints.media-breakpoint-up(md) {
          margin: 0 0 17px;
        }
      }
    }
  }

  .title-bar {
    margin: 0 0 5px;

    .title {
      font-size: 14px;
      letter-spacing: 0.44px;
    }

    time {
      letter-spacing: 0.38px;
    }
  }

  .img-holder {
    border-radius: bootstrap-custom.$border-radius;
    overflow: hidden;

    img {
      @include mixins.responsive-image;
    }
  }

  .pdf-holder {
    background: vars.$gray-900;
    border-radius: 2px;
    margin: 4px 18px 6px 0;
    width: 70px;

    @include breakpoints.media-breakpoint-up(sm) {
      margin: 4px 18px 6px 0;
      width: 85px;
    }

    img {
      @include mixins.responsive-image;
      left: 6px;
      position: relative;
      top: 6px;
    }
  }

  .comment-form {
    .form-control {
      background: vars.$gray-10;
    }
  }
}
/* progress card styles */
.progress-card {
  align-items: center;
  border: 0;
  flex-flow: row nowrap;
  padding: 10px;

  @include breakpoints.media-breakpoint-up(md) {
    padding: 10px 24px 11px 12px;
  }

  .info {
    flex-grow: 1;
  }

  .title-bar {
    margin: 0 0 5px;
    padding: 0;

    @include breakpoints.media-breakpoint-up(md) {
      margin: 0 0 10px;
    }
  }

  .progress {
    margin: 0 0 6px;
  }

  .users {
    flex-shrink: 0;
    width: 74px;

    @include breakpoints.media-breakpoint-up(sm) {
      width: 145px;
    }
  }
}
/* switcher card styles */
.switcher-card {
  border: 0;
  color: vars.$white;
  font-size: 14px;
  padding: 10px 60px 10px 10px;

  @include breakpoints.media-breakpoint-up(md) {
    font-size: 16px;
    min-height: 50px;
    padding: 13px 60px 13px 12px;
  }

  .switcher {
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
  }

  p {
    &:last-child {
      margin: 0;
    }
  }
}
/* approved-by styles */
.approved-by {
  align-items: center;
  display: flex;
  padding: 0 13px 12px;

  .img-holder {
    @include mixins.size(36px);
    border-radius: 100%;
    flex-shrink: 0;
    margin: 0 12px 0 0;
    overflow: hidden;

    img {
      @include mixins.responsive-image;
    }
  }

  .text {
    flex-grow: 1;
    text-transform: uppercase;
  }

  .heading {
    display: block;
    font-size: 10px;
    line-height: 16px;
    opacity: 0.5;
  }

  .title {
    color: vars.$white;
    display: block;
    font-size: 15px;
    line-height: 18px;

    a {
      color: inherit;

      &:hover {
        color: vars.$primary;
      }
    }
  }
}

.alarm-accordion {
  + .conversation {
    padding-top: 27px;
  }

  > .card {
    background: none;
    border-radius: 0;
    padding: 0;

    > .checkbox {
      left: 0;
      position: absolute;
      top: 13px;
      z-index: 9;

      @include breakpoints.media-breakpoint-up(sm) {
        top: 17px;
      }

      + .heading-bar {
        padding-left: 44px;

        @include breakpoints.media-breakpoint-up(sm) {
          padding-left: 30px;
        }

        [class*='icon-'] {
          left: 22px;
        }
      }
    }
  }

  .heading-bar {
    box-shadow: 0 1px 0 vars.$gray-700;
    flex-flow: row wrap;
    margin: 0;
    padding: 10px 22px 10px 22px;

    @include breakpoints.media-breakpoint-up(sm) {
      min-height: 50px;
      padding: 13px 36px 13px 0;
    }

    @include breakpoints.media-breakpoint-up(lg) {
      flex-flow: row nowrap;
    }

    &.collapsed {
      &:after {
        content: '\e906';
      }
    }

    &:after {
      @include mixins.icon('\e905');
      font-size: 8px;
      position: absolute;
      right: 8px;
      top: 14px;

      @include breakpoints.media-breakpoint-up(sm) {
        top: 18px;
      }
    }

    [class*='icon-'] {
      left: 0;
      position: absolute;
      top: 9px;

      @include breakpoints.media-breakpoint-up(sm) {
        position: static;
      }
    }

    .heading {
      @include breakpoints.media-breakpoint-up(md) {
        margin: 0 10px 0 0;
      }
    }
  }

  .card-body {
    padding: 12px 0;
  }

  .location-form {
    margin-bottom: 0;
  }

  .approved-by {
    padding-bottom: 0;
  }
}
/* intake breakdown styles */
.intake-breakdown {
  height: 100%;
  position: relative;

  &.style2 {
    @include breakpoints.media-breakpoint-up(lg) {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  .content-header {
    flex-shrink: 0;
    margin: 0;
  }

  .scroll-area {
    padding: 7px 12px;

    @include breakpoints.media-breakpoint-up(lg) {
      flex: 1 1 0;
      overflow-y: auto;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
      }
    }

    &.scrollable-holder {
      padding: 0;

      .scrollable-frame {
        padding: 7px 12px;
      }
    }
  }

  .block {
    padding-bottom: 12px;
    padding-top: 12px;
  }

  .heading-bar {
    display: block;
    font-size: 12px;
    margin: 0 0 13px;
    padding: 0 40px 0 12px;

    .heading {
      font-size: 16px;
      margin: 0 0 2px;
    }

    .section-dropdown {
      position: absolute;
      right: 12px;
      top: 0;
    }
  }

  .card {
    background: vars.$gray-800;
    padding: 10px;

    @include breakpoints.media-breakpoint-up(md) {
      padding: 12px;
    }

    @include breakpoints.media-breakpoint-up(xl) {
      padding: 24px;
    }
  }

  .footer {
    background: vars.$gray-700;
    flex-shrink: 0;
    padding: 12px 12px 0;
    position: relative;
    z-index: 99;

    @include breakpoints.media-breakpoint-up(lg) {
      height: 108px;
    }

    .card {
      align-items: flex-end;
      border: 1px solid vars.$gray-900;
      flex-direction: row;
      height: 84px;
      margin: 0 0 12px;

      @include breakpoints.media-breakpoint-up(lg) {
        padding: 12px 5px;
      }

      @include breakpoints.media-breakpoint-up(xl) {
        padding: 13px 24px;
      }
    }

    .btn-filter {
      color: inherit;
      font-size: 18px;
      line-height: 1;
      position: absolute;
      right: 14px;
      top: 13px;

      &:hover {
        color: vars.$primary;
      }

      i {
        display: block;
      }
    }

    .pie-cart {
      @include mixins.size(54px);
      margin: 0 9px 3px 3px;

      @include breakpoints.media-breakpoint-only(lg) {
        @include mixins.size(50px);
      }

      .value {
        font-size: 12px;
      }
    }

    .text {
      padding: 10px 0 0 0;
    }

    .heading {
      color: vars.$white;
      display: block;
      font-size: 16px;
      line-height: 1;
      padding: 0 10px 0 0;

      @include breakpoints.media-breakpoint-up(lg) {
        font-size: 14px;
      }

      @include breakpoints.media-breakpoint-up(xl) {
        font-size: 20px;
        padding: 0 10px 0 0;
      }

      [class*='icon-'] {
        margin: 1px 5px 1px 0;
      }
    }

    .subhead {
      display: block;
      opacity: 0.5;
      text-transform: uppercase;

      @include breakpoints.media-breakpoint-only(lg) {
        line-height: 1.3;
      }
    }
  }
}
/* range-holder styles */
.range-holder {
  margin: 0 0 2px;
}
/* incoming styles */
.incoming {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  &-list {
    color: vars.$white;
    flex-shrink: 0;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    margin: 0 10px 0 0;

    @include breakpoints.media-breakpoint-up(md) {
    }

    @include breakpoints.media-breakpoint-up(xl) {
      margin: 0 24px 0 0;
    }

    li {
      margin: 0 0 12px;
      padding: 0 0 0 27px;
      position: relative;

      &:last-child {
        margin: 0;
      }

      [class*='icon-'] {
        left: 0;
        position: absolute;
        top: 0;
      }
    }
  }

  .incoming-graph {
    flex-grow: 1;

    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      width: 107px;
    }
  }
}
/* priority styles */
.priority {
  &.card {
    @include breakpoints.media-breakpoint-up(xl) {
      padding-bottom: 20px;
      padding-top: 20px;
    }
  }

  .pie-cart {
    margin: 0 10px 0 0;

    @include breakpoints.media-breakpoint-up(md) {
    }

    @include breakpoints.media-breakpoint-up(xl) {
      margin: 0 23px 0 0;
    }
  }
}
/* tags list styles */
.tags-list {
  display: flex;
  flex-flow: row wrap;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  margin: -2px;
  text-transform: uppercase;

  @include breakpoints.media-breakpoint-up(md) {
    font-size: 14px;
    line-height: 20px;
    margin: -4px;
  }

  @include breakpoints.media-breakpoint-up(lg) {
    margin: -6px;
  }

  li {
    padding: 2px;

    @include breakpoints.media-breakpoint-up(md) {
      padding: 4px;
    }

    @include breakpoints.media-breakpoint-up(lg) {
      padding: 6px;
    }
  }

  a {
    background: vars.$primary;
    border-radius: 20px;
    color: vars.$white;
    display: block;
    padding: 4px 10px;

    @include breakpoints.media-breakpoint-up(md) {
      padding: 5px 15px;
    }

    @include breakpoints.media-breakpoint-up(lg) {
      padding: 6px 19px;
    }

    &:hover {
      background: color.adjust(vars.$primary, $lightness: -5%);
    }
  }
}
/* evetns calendar styles */

.evetns-calendar {
  margin: 0 0 20px;
  padding: 10px;

  @include breakpoints.media-breakpoint-up(md) {
    margin: 0 0 36px;
    padding: 12px;
  }
}

.calendar-head {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 13px;

  .month {
    color: vars.$white;
    flex-grow: 1;
    font-size: 20px;
    line-height: 24px;
    padding: 0 12px;
    text-transform: capitalize;

    @include breakpoints.media-breakpoint-up(md) {
      font-size: 25px;
      line-height: 32px;
    }

    @include breakpoints.media-breakpoint-up(lg) {
      font-size: 30px;
      line-height: 38px;
    }
  }
}
/* calendar pager styles */
.calendar-pager {
  background: vars.$gray-800;
  border-radius: bootstrap-custom.$border-radius;
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
  height: 27px;
  padding: 4px 10px;

  @include breakpoints.media-breakpoint-up(sm) {
    padding: 4px 14px;
  }

  .txt {
    flex-grow: 1;
    padding: 0 5px;

    @include breakpoints.media-breakpoint-up(sm) {
      padding: 0 9px;
    }
  }

  a {
    color: inherit;

    &:hover {
      color: vars.$primary;
    }
  }
}
/* ongoing events list styles */
.ongoing-list {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -4px;

  @include breakpoints.media-breakpoint-up(sm) {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -6px;
  }

  li {
    align-items: center;
    background: vars.$gray-800;
    border-radius: bootstrap-custom.$border-radius;
    display: flex;
    margin: 0 4px 10px;
    padding: 5px 10px;
    width: calc(50% - 8px);

    @include breakpoints.media-breakpoint-up(sm) {
      margin: 0 6px 12px;
      padding: 7px 12px;
      width: calc(50% - 12px);
    }

    @include breakpoints.media-breakpoint-up(xl) {
      width: calc(25% - 12px);
    }
  }

  .number {
    color: vars.$white;
    flex-shrink: 0;
    font-size: 16px;
    line-height: 20px;
    margin: 0 5px 0 0;
    max-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include breakpoints.media-breakpoint-up(sm) {
      font-size: 25px;
      line-height: 30px;
      margin: 0 9px 0 0;
      max-width: 80px;
    }

    @include breakpoints.media-breakpoint-up(lg) {
      font-size: 30px;
      line-height: 34px;
      max-width: 94px;
    }

    @include breakpoints.media-breakpoint-up(xl) {
    }
  }

  .txt {
    flex-grow: 1;
    font-size: 9px;
    font-weight: bold;
    line-height: 13px;
    opacity: 0.5;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;

    @include breakpoints.media-breakpoint-up(sm) {
      font-size: 12px;
    }
  }
}
/* evetns table styles */
.evetns-table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;

  th {
    color: vars.$white;
    font-size: 12px;
    line-height: 16px;
    padding: 3px 5px;
    text-transform: uppercase;

    @include breakpoints.media-breakpoint-up(sm) {
      font-size: 14px;
      line-height: 18px;
      padding: 3px 12px;
    }

    @include breakpoints.media-breakpoint-up(lg) {
      font-size: 16px;
      line-height: 21px;
    }
  }

  td {
    background: vars.$gray-800;
    border: 1px solid vars.$gray-900;
    border-radius: bootstrap-custom.$border-radius;
    cursor: pointer;
    font-size: 7px;
    line-height: 8px;
    padding: 6px 5px;
    vertical-align: top;
    @extend %transition !optional;

    @include breakpoints.media-breakpoint-up(sm) {
      font-size: 8px;
    }

    @include breakpoints.media-breakpoint-up(lg) {
      height: 110px;
      padding: 7px 12px 9px;
    }

    &:hover {
      background: vars.$gray-700;
    }

    &.today {
      background: vars.$gray-700;

      .date {
        color: vars.$primary;
        font-weight: bold;
      }
    }

    &.last-month,
    &.next-month {
      background: rgba(vars.$gray-800, 0.5);

      > * {
        opacity: 0.3;
        @extend %transition !optional;
      }

      a.more {
        color: vars.$gray-200;

        &:hover {
          color: vars.$primary;
        }
      }

      &:hover {
        background: vars.$gray-900;

        > * {
          opacity: 1;
        }
      }
    }
  }

  .date {
    color: vars.$white;
    display: block;
    font-size: 18px;
    font-weight: 300;
    line-height: 1;
    margin: 0 0 5px;

    @include breakpoints.media-breakpoint-up(sm) {
      font-size: 25px;
    }

    @include breakpoints.media-breakpoint-up(lg) {
      font-size: 30px;
      margin: 0 0 18px;
    }
  }

  a.more {
    @include mixins.align(top);
    color: vars.$white;
    font-weight: bold;

    &:hover {
      color: vars.$primary;
    }
  }
}

.calendar-event-list {
  color: rgba(vars.$gray-500, 0.5);
  margin: 0;

  li {
    margin: 0 0 2px;
    overflow: hidden;
    padding: 0 0 0 6px;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include breakpoints.media-breakpoint-up(sm) {
      padding: 0 0 0 10px;
    }

    &:nth-child(3n + 2):before {
      background: vars.$success;
    }

    &:nth-child(3n + 3):before {
      background: vars.$purple;
    }

    &:before {
      @include mixins.size(4px);
      @include mixins.pseudo-elements(50%, auto, auto, 0);
      background: vars.$blue;
      border-radius: 100%;
      transform: translateY(-50%);

      @include breakpoints.media-breakpoint-up(sm) {
        @include mixins.size(6px);
      }
    }
  }
}
/* date range style s*/
.date-range {
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  margin: 0 -5px 5px;

  li {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    padding: 0 5px;

    &:before {
      color: vars.$gray-200;
      text-transform: uppercase;
    }

    &:first-child {
      &:before {
        content: 'Start';
      }
    }

    &:last-child {
      flex-direction: row-reverse;

      &:before {
        content: 'End';
      }
    }
  }

  [class*='icon'] {
    padding: 0 5px;
  }
}
/* graph plots styles */
.graph-plots {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -6px 0 0;
  padding: 0 0 10px;

  @include breakpoints.media-breakpoint-up(md) {
    margin: 0 -11px 0 0;
  }

  li {
    margin: 0 6px 6px 0;
    position: relative;

    @include breakpoints.media-breakpoint-up(md) {
      margin: 0 11px 10px 0;
    }
  }

  .btn {
    color: vars.$white;
    font-weight: normal;
    line-height: 17px;
    min-width: inherit;
    padding: 4px 9px 4px 22px;
    text-transform: capitalize;

    @include breakpoints.media-breakpoint-up(md) {
      padding: 4px 9px 4px 27px;
    }

    &:hover {
      + .btn-close {
        color: vars.$white !important;
      }
    }
  }

  .btn-close {
    left: 6px;
    right: auto;
    top: 6px;
    transform: none;

    @include breakpoints.media-breakpoint-up(md) {
      left: 7px;
    }
  }
}
/* troubleshoot styles */
.troubleshoot {
  margin: 0 0 30px;
}
