@use 'libs/styles/vars';
@use '@swimlane/ngx-datatable/themes/dark.scss';
@use '@swimlane/ngx-datatable/themes/material.scss';

// Table body styles
ngx-datatable {
  .empty-row {
    display: none;
  }
}

.vfi-loader,
.datatable-body {
  // loading indicator
  .progress-linear {
    display: block;
    position: sticky;
    width: 100%;
    height: 5px;
    padding: 0;
    margin: 0;
    top: 0;

    .container {
      display: block;
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 5px;
      transform: translate(0, 0) scale(1, 1);
      background-color: vars.$gray-5;
      max-width: 100% !important;

      .bar {
        animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
        transition: transform 0.2s linear;
        background-color: vars.$gray-2;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 5px;
      }
    }
  }
}

.noDataTable {
  .datatable-body {
    display: none !important;
  }
}

.baseTable {
  .datatable-body {
    max-height: calc(100vh - 325px) !important;
  }
}

.filteredTable {
  .datatable-body {
    max-height: calc(100vh - 350px) !important;
  }
}

.datatable-body-cell {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  border-right: 1px solid vars.$gray-10 !important;
}

.datatable-body-cell-label {
  width: 100% !important;
  height: 100% !important;
}

// Table header and cell styles
.datatable-icon-sort-unset {
  display: none !important;
}

.datatable-header,
.datatable-body-row {
  background: vars.$gray-8 !important;
  color: vars.$neutral-1 !important;
  border: 1px solid vars.$gray-10;
}

.datatable-header,
.datatable-header-inner {
  height: 36px !important;
}

.dataTableSelected {
  .datatable-header {
    background: vars.$gray-10 !important;
  }
}

.datatable-header-cell {
  border-right: 1px solid vars.$gray-10 !important;
  height: 100% !important;
}

.datatable-body-row.active {
  background: vars.$gray-10 !important;
}

.ngx-datatable.dark .datatable-header .datatable-header-cell {
  padding: 8px;
}

.ngx-datatable.dark .datatable-body .datatable-body-row .datatable-body-cell {
  padding: 0;
}

.datatable-header-cell-template-wrap {
  display: flex !important;
  justify-content: center !important;
}

.ngx-datatable.dark .datatable-body {
  background: transparent !important;
}
