@use 'libs/styles/vars';

@each $name, $value in vars.$sizes {
  .w-#{$name} {
    width: $value;
  }
}

@each $name, $value in vars.$sizes {
  .iw-#{$name} {
    width: $value !important;
  }
}

@each $name, $value in vars.$sizes {
  .min-w-#{$name} {
    min-width: $value;
  }
}

@each $name, $value in vars.$sizes {
  .imin-w-#{$name} {
    min-width: $value !important;
  }
}

@each $name, $value in vars.$sizes {
  .max-w-#{$name} {
    max-width: $value;
  }
}

@each $name, $value in vars.$sizes {
  .imax-w-#{$name} {
    max-width: $value !important;
  }
}

@each $name, $value in vars.$sizes {
  .h-#{$name} {
    height: $value;
  }
}

@each $name, $value in vars.$sizes {
  .ih-#{$name} {
    height: $value;
  }
}

@each $name, $value in vars.$sizes {
  .min-h-#{$name} {
    min-height: $value;
  }
}

@each $name, $value in vars.$sizes {
  .imin-h-#{$name} {
    min-height: $value;
  }
}

@each $name, $value in vars.$sizes {
  .max-h-#{$name} {
    max-height: $value;
  }
}

@each $name, $value in vars.$sizes {
  .imax-h-#{$name} {
    max-height: $value;
  }
}
