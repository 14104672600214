@use 'sass:color';
@use 'sass:list';
@use 'sass:map';
@use 'libs/styles/vars';
@use 'vendors/bootstrap/functions';

// custom variables and override bootstrap variables

$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map.merge(
  (
    '100': vars.$gray-100,
    '200': vars.$gray-200,
    '300': vars.$gray-300,
    '400': vars.$gray-400,
    '500': vars.$gray-500,
    '600': vars.$gray-600,
    '700': vars.$gray-700,
    '800': vars.$gray-800,
    '900': vars.$gray-900,
  ),
  $grays
);

$colors: ();
$colors: map.merge(
  (
    'blue': vars.$blue,
    'indigo': vars.$indigo,
    'purple': vars.$purple,
    'pink': vars.$pink,
    'red': vars.$red,
    'orange': vars.$orange,
    'yellow': vars.$yellow,
    'green': vars.$green,
    'teal': vars.$teal,
    'cyan': vars.$cyan,
    'white': vars.$white,
    'gray': vars.$gray-600,
    'gray-dark': vars.$gray-800,
  ),
  $colors
);

$primary: vars.$blue-light;
$secondary: vars.$gray-600;
$success: vars.$green;
$info: vars.$purple;
$warning: vars.$yellow;
$danger: vars.$red;
$light: vars.$gray-500;
$dark: vars.$gray01;

$theme-colors: ();
$theme-colors: map.merge(
  (
    'primary': vars.$primary,
    'secondary': vars.$secondary,
    'success': vars.$success,
    'info': vars.$info,
    'warning': vars.$warning,
    'danger': vars.$danger,
    'light': vars.$light,
    'dark': vars.$dark,
  ),
  $theme-colors
);

$spacer: 1rem;
$spacers: ();
$spacers: map.merge(
  (
    0: 0,
    1: (
      $spacer * 0.375,
    ),
    2: (
      $spacer * 0.75,
    ),
    3: (
      $spacer * 0.9375,
    ),
    4: (
      $spacer * 1.25,
    ),
    5: (
      $spacer * 1.5625,
    ),
    6: (
      $spacer * 1.875,
    ),
    7: (
      $spacer * 2.1875,
    ),
    8: (
      $spacer * 2.5,
    ),
    9: (
      $spacer * 9,
    ),
    10: (
      $spacer * 10.5,
    ),
  ),
  $spacers
);

// This is the default html and body font-size for the base rem value.
$rem-base: 16px;

// Body
$base-min-width: 320px;

$body-bg: vars.$gray-9;
$body-color: vars.$gray-500;

// Links

$link-color: vars.theme-color('primary');
$link-decoration: none;
$link-hover-color: vars.$white;
$link-hover-decoration: none;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;

// Paragraphs

$paragraph-margin-bottom: 0.375rem;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grid-breakpoints: map.merge(
  (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    tv: 1400px,
  ),
  $grid-breakpoints
);

@include functions.assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include functions.assert-starts-at-zero($grid-breakpoints);

// Grid containers

$container-max-widths: ();
// stylelint-disable-next-line scss/dollar-variable-default
$container-max-widths: map.merge(
  (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
  ),
  $container-max-widths
);

@include functions.assert-ascending(
  $container-max-widths,
  '$container-max-widths'
);

// Grid columns

$grid-columns: 12;
$grid-gutter-width: 12px;

// Components

$line-height-lg: 1.5;
$line-height-sm: 1.5;

$border-width: 1px;
$border-color: vars.$gray-300;

$border-radius: 0.25rem;
$border-radius-lg: 0.3rem;
$border-radius-sm: 0.125rem;

$rounded-pill: 50rem !default;

$box-shadow-sm: 0 0.125rem 0.25rem rgba(vars.$black, 0.075);
$box-shadow: 0 0.5rem 1rem rgba(vars.$black, 0.15);
$box-shadow-lg: 0 1rem 3rem rgba(vars.$black, 0.175);

$component-active-color: vars.$white;
$component-active-bg: vars.theme-color('primary');

$caret-width: 0.3em;

$transition-base: all 0.2s ease-in-out;
$transition-fade: opacity 0.15s linear;
$transition-collapse: height 0.35s ease;

$embed-responsive-aspect-ratios: ();
// stylelint-disable-next-line scss/dollar-variable-default
$embed-responsive-aspect-ratios: list.join(
  ((21 9), (16 9), (3 4), (1 1)),
  $embed-responsive-aspect-ratios
);

// Fonts

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Roboto', Arial, 'Noto Sans', sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  'Liberation Mono', 'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-base: 0.75rem; // Assumes the browser default, typically `12px`
$font-size-lg: ($font-size-base * 1.3333);
$font-size-sm: ($font-size-base * 0.8333);

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 1.6666;
$h3-font-size: $font-size-base;
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base * 0.9;
$h6-font-size: $font-size-base * 0.8;

$headings-margin-bottom: $spacer / 2;
$headings-font-family: inherit;
$headings-font-weight: $font-weight-bold;
$headings-line-height: inherit;
$headings-color: vars.$white;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

$display1-weight: 300 !default;
$display2-weight: 300 !default;
$display3-weight: 300 !default;
$display4-weight: 300 !default;
$display-line-height: $headings-line-height !default;

$lead-font-size: ($font-size-base * 1.25) !default;
$lead-font-weight: 300 !default;

$small-font-size: 80% !default;

$text-muted: vars.$gray-600 !default;

$blockquote-small-color: vars.$gray-600 !default;
$blockquote-small-font-size: $small-font-size !default;
$blockquote-font-size: ($font-size-base * 1.25) !default;

$hr-border-color: vars.$gray-600;
$hr-border-width: $border-width;

$mark-padding: 0.2em !default;

$dt-font-weight: $font-weight-bold !default;

$kbd-box-shadow: inset 0 -0.1rem 0 rgba(vars.$black, 0.25) !default;
$nested-kbd-font-weight: $font-weight-bold !default;

$list-inline-padding: 0.5rem !default;

$mark-bg: #fcf8e3 !default; // sass-lint:disable-line no-color-hex

$hr-margin-y: 1.5rem;

// Buttons + Forms

$input-btn-padding-y: 0.625rem;
$input-btn-padding-x: 0.75rem;
$input-btn-font-size: $font-size-base;
$input-btn-line-height: $line-height-base;

$input-btn-focus-width: 0;
$input-btn-focus-color: rgba(vars.$component-active-bg, 0.25);
$input-btn-focus-box-shadow: none;

$input-btn-padding-y-sm: 0.5rem;
$input-btn-padding-x-sm: 0.75rem;
$input-btn-font-size-sm: $font-size-sm !default;
$input-btn-line-height-sm: $line-height-sm !default;

$input-btn-padding-y-lg: 0.75rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-font-size-lg: $font-size-lg;
$input-btn-line-height-lg: $line-height-lg;

$input-btn-border-width: $border-width;

// Buttons

$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-font-size: 14px;
$btn-line-height: 18px;

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;
$btn-line-height-sm: $input-btn-line-height-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: $input-btn-font-size-lg;
$btn-line-height-lg: $input-btn-line-height-lg;

$btn-border-width: $input-btn-border-width;

$btn-font-weight: $font-weight-normal;
$btn-box-shadow: none;
$btn-focus-width: $input-btn-focus-width;
$btn-focus-box-shadow: none;
$btn-disabled-opacity: 0.65;
$btn-active-box-shadow: none;

$btn-link-disabled-color: vars.$gray-600 !default;

$btn-block-spacing-y: 0.5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius-lg;
$btn-border-radius-sm: $border-radius-sm;

$btn-transition:
  color 0.15s ease-in-out,
  background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out !default;

// Forms

$label-margin-bottom: 0;

$input-padding-y: 0.6875rem;
$input-padding-x: $input-btn-padding-x;
$input-font-size: $input-btn-font-size;
$input-font-weight: $font-weight-bold;
$input-line-height: $input-btn-line-height;

$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-font-size-sm: $input-btn-font-size-sm;
$input-line-height-sm: $input-btn-line-height-sm;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-font-size-lg: $input-btn-font-size-lg;
$input-line-height-lg: $input-btn-line-height-lg;

$input-bg: vars.$gray-700;
$input-disabled-bg: vars.$gray-200 !default;

$input-color: vars.$gray-200;
$input-border-color: $input-bg;
$input-border-width: 0;
$input-box-shadow: none;

$input-border-radius: $border-radius;
$input-border-radius-lg: $border-radius-lg;
$input-border-radius-sm: $border-radius-sm;

$input-focus-bg: $input-bg;
$input-focus-border-color: color.adjust($component-active-bg, $lightness: 25%);
$input-focus-color: $input-color;
$input-focus-width: $input-btn-focus-width;
$input-focus-box-shadow: $input-btn-focus-box-shadow;

$input-placeholder-color: rgba(vars.$gray-500, 0.5);
$input-plaintext-color: $body-color;

$input-height-border: $input-border-width * 2;

$input-height-inner: ($input-btn-font-size * $input-btn-line-height) +
  ($input-btn-padding-y * 2);
$input-height: calc(#{$input-height-inner} + #{$input-height-border});

$input-height-inner-sm: ($input-btn-font-size-sm * $input-btn-line-height-sm) +
  ($input-btn-padding-y-sm * 2);
$input-height-sm: calc(#{$input-height-inner-sm} + #{$input-height-border});

$input-height-inner-lg: ($input-btn-font-size-lg * $input-btn-line-height-lg) +
  ($input-btn-padding-y-lg * 2);
$input-height-lg: calc(#{$input-height-inner-lg} + #{$input-height-border});

$input-transition:
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;

$form-text-margin-top: 0.25rem !default;

$form-check-input-gutter: 1.25rem !default;
$form-check-input-margin-y: 0.3rem !default;
$form-check-input-margin-x: 0.25rem !default;

$form-check-inline-margin-x: 0.75rem !default;
$form-check-inline-input-margin-x: 0.3125rem !default;

$form-grid-gutter-width: 10px !default;
$form-group-margin-bottom: 0.75rem;

$custom-range-track-width: 100%;
$custom-range-track-height: 0.1875rem;
$custom-range-track-cursor: pointer;
$custom-range-track-bg: linear-gradient(
  to right,
  vars.$teal 0%,
  vars.$green 25%,
  vars.$yellow 50%,
  vars.$pink 75%,
  vars.$red 100%
);
$custom-range-track-border-radius: 1rem;
$custom-range-track-box-shadow: none;

$custom-range-thumb-width: 0.75rem;
$custom-range-thumb-height: $custom-range-thumb-width;
$custom-range-thumb-bg: $danger;
$custom-range-thumb-border: 20px;
$custom-range-thumb-border-radius: 1rem;
$custom-range-thumb-box-shadow: 0 0.1rem 0.25rem rgba(vars.$black, 0.1);
$custom-range-thumb-focus-box-shadow:
  0 0 0 1px $body-bg,
  $input-focus-box-shadow;
$custom-range-thumb-focus-box-shadow-width: $input-focus-width; // For focus box shadow issue in IE/Edge
$custom-range-thumb-active-bg: color.adjust(
  $component-active-bg,
  $lightness: 35%
);
$custom-range-thumb-disabled-bg: vars.$gray-500;

// Dropdowns

$dropdown-min-width: 10rem;
$dropdown-padding-y: 0;
$dropdown-spacer: 0.5rem;
$dropdown-bg: vars.$gray-600;
$dropdown-border-color: vars.$gray-600;
$dropdown-border-radius: $border-radius;
$dropdown-border-width: 0;
$dropdown-inner-border-radius: calc(
  #{$dropdown-border-radius} - #{$dropdown-border-width}
);
$dropdown-divider-bg: vars.$gray-200;
$dropdown-box-shadow: 0 0.4rem 1rem rgba(vars.$gray01, 0.5);

$dropdown-link-color: vars.$gray-200;
$dropdown-link-hover-color: vars.$white;
$dropdown-link-hover-bg: rgba(vars.$white, 0.1);

$dropdown-link-active-color: $component-active-color;
$dropdown-link-active-bg: $component-active-bg;

$dropdown-link-disabled-color: vars.$gray-600;

$dropdown-item-padding-y: 0.5rem;
$dropdown-item-padding-x: 0.75rem;

$dropdown-header-color: vars.$gray-600;

// Badges

$badge-font-size: 75%;
$badge-font-weight: $font-weight-bold;
$badge-padding-y: 0.1428em;
$badge-padding-x: 0.4em;
$badge-border-radius: 0.1428em;

// Cards

$card-spacer-y: 0.5rem;
$card-spacer-x: 0.6875rem;
$card-border-width: $border-width;
$card-border-radius: $border-radius;
$card-border-color: transparent;
$card-inner-border-radius: none;
$card-cap-bg: rgba(vars.$black, 0.03) !default;
$card-cap-color: inherit !default;
$card-bg: vars.$gray-700;

$card-img-overlay-padding: 1.25rem !default;

$card-group-margin: $grid-gutter-width / 2 !default;
$card-deck-margin: $card-group-margin !default;

$card-columns-count: 3 !default;
$card-columns-gap: 1.25rem !default;
$card-columns-margin: $card-spacer-y !default;

// Modals

// Padding applied to the modal body
$modal-inner-padding: 1rem 0.75rem;

$modal-dialog-margin: 0.5rem;
$modal-dialog-margin-y-sm-up: 1.75rem;

$modal-title-line-height: $line-height-base;

$modal-content-bg: vars.$gray-600;
$modal-content-border-color: transparent;
$modal-content-border-width: 0;
$modal-content-border-radius: $border-radius;
$modal-content-box-shadow-xs: 0 5px 10px rgba(vars.$black, 0.3);
$modal-content-box-shadow-sm-up: 0 5px 10px rgba(vars.$black, 0.3);

$modal-backdrop-bg: vars.$black;
$modal-backdrop-opacity: 0.9;
$modal-header-border-color: vars.$gray-900;
$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-header-border-width;
$modal-header-padding-y: 1rem;
$modal-header-padding-x: 0.75rem;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x;

$modal-xl: 1140px;
$modal-lg: 984px;
$modal-md: 460px;
$modal-sm: 300px;

$modal-fade-transform: translate(0, -50px) !default;
$modal-show-transform: none !default;
$modal-transition: transform 0.3s ease-out !default;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: 0.5rem;
$alert-padding-x: 0.5rem;
$alert-margin-bottom: 1rem;
$alert-border-radius: $border-radius-sm;
$alert-link-font-weight: $font-weight-bold;
$alert-border-width: 0;

$alert-bg-level: -10 !default;
$alert-border-level: -9 !default;
$alert-color-level: 6 !default;

// Progress bars

$progress-height: 0.25rem;
$progress-font-size: ($font-size-base * 0.75) !default;
$progress-bg: transparent;
$progress-border-radius: $border-radius-sm;
$progress-box-shadow: none;
$progress-bar-color: vars.$white;
$progress-bar-bg: vars.theme-color('primary');
$progress-bar-animation-timing: 1s linear infinite;
$progress-bar-transition: width 0.6s ease;
