@use 'libs/styles/vars';

@each $name in vars.$position {
  .position-#{$name} {
    position: $name;
  }
}

@each $name in vars.$position {
  .iposition-#{$name} {
    position: $name !important;
  }
}
