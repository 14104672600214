@use 'libs/styles/vars';

@each $name in vars.$visibility {
  .visibility-#{$name} {
    visibility: $name;
  }
}
@each $name in vars.$visibility {
  .ivisibility-#{$name} {
    visibility: $name !important;
  }
}
