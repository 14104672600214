@use 'libs/styles/vars';
// sass-lint:disable no-qualifying-elements

.showOnPrint {
  display: none;
  @media print {
    display: block;
  }
}

.hideOnPrint {
  @media print {
    display: none !important;
  }
}

@media print {
  .print-no-margin {
    margin: 0 !important;
  }

  .print-margin {
    padding-top: 10mm;
  }

  @page {
    max-height: 100%;
    max-width: 100%;
    size: A4 landscape;
  }

  .chart {
    height: 90%;
    margin: 0;
    margin-left: -8mm !important;
    margin-right: 6mm !important;
    margin-top: 20mm !important;
    padding: 0;
    zoom: 0.72;
  }

  body {
    background-color: vars.$neutral-1 !important;
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    width: 1180px;
  }

  .ant-table-wrapper {
    margin-right: 6mm !important;
    zoom: 0.85 !important;
  }

  .gray-1-bg,
  .gray-2-bg,
  .gray-3-bg,
  .gray-4-bg,
  .gray-5-bg,
  .gray-6-bg,
  .gray-7-bg,
  .gray-8-bg,
  .gray-9-bg {
    background-color: vars.$neutral-1 !important;
  }

  .border-100,
  .border-200,
  .border-300,
  .border-400,
  .border-500,
  .border-600,
  .border-700,
  .border-800,
  .border-900 {
    border-color: vars.$neutral-1 !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7,
  h8,
  h9 {
    color: vars.$neutral-10 !important;
  }

  ::ng-deep rect.highcharts-background {
    background-color: vars.$neutral-1 !important;
    fill: vars.$neutral-1 !important;
  }

  .highcharts-legend-item highcharts-column-series {
    text {
      fill: vars.$neutral-10 !important;
    }
  }
}
