@use 'libs/styles/vars';

@each $name, $value in vars.$neutrals {
  .#{$name} {
    color: $value;
  }
}
@each $name, $value in vars.$neutrals {
  .i#{$name} {
    color: $value !important;
  }
}
@each $name, $value in vars.$neutrals {
  .#{$name}-bg {
    background-color: $value;
  }
}
@each $name, $value in vars.$neutrals {
  .i#{$name}-bg {
    background-color: $value !important;
  }
}

@each $name, $value in vars.$blues {
  .#{$name} {
    color: $value;
  }
}
@each $name, $value in vars.$blues {
  .i#{$name} {
    color: $value !important;
  }
}
@each $name, $value in vars.$blues {
  .#{$name}-bg {
    background-color: $value;
  }
}
@each $name, $value in vars.$blues {
  .i#{$name}-bg {
    background-color: $value !important;
  }
}

@each $name, $value in vars.$reds {
  .#{$name} {
    color: $value;
  }
}
@each $name, $value in vars.$reds {
  .i#{$name} {
    color: $value !important;
  }
}
@each $name, $value in vars.$reds {
  .#{$name}-bg {
    background-color: $value;
  }
}
@each $name, $value in vars.$reds {
  .i#{$name}-bg {
    background-color: $value !important;
  }
}
@each $name, $value in vars.$alt-reds {
  .#{$name} {
    color: $value;
  }
}
@each $name, $value in vars.$alt-reds {
  .i#{$name} {
    color: $value !important;
  }
}
@each $name, $value in vars.$alt-reds {
  .#{$name}-bg {
    background-color: $value;
  }
}
@each $name, $value in vars.$alt-reds {
  .i#{$name}-bg {
    background-color: $value !important;
  }
}

@each $name, $value in vars.$oranges {
  .#{$name} {
    color: $value;
  }
}
@each $name, $value in vars.$oranges {
  .i#{$name} {
    color: $value !important;
  }
}
@each $name, $value in vars.$oranges {
  .#{$name}-bg {
    background-color: $value;
  }
}
@each $name, $value in vars.$oranges {
  .i#{$name}-bg {
    background-color: $value !important;
  }
}

@each $name, $value in vars.$yellows {
  .#{$name} {
    color: $value;
  }
}
@each $name, $value in vars.$yellows {
  .i#{$name} {
    color: $value !important;
  }
}
@each $name, $value in vars.$yellows {
  .#{$name}-bg {
    background-color: $value;
  }
}
@each $name, $value in vars.$yellows {
  .i#{$name}-bg {
    background-color: $value !important;
  }
}

@each $name, $value in vars.$greens {
  .#{$name} {
    color: $value;
  }
}
@each $name, $value in vars.$greens {
  .i#{$name} {
    color: $value !important;
  }
}
@each $name, $value in vars.$greens {
  .#{$name}-bg {
    background-color: $value;
  }
}
@each $name, $value in vars.$greens {
  .i#{$name}-bg {
    background-color: $value !important;
  }
}

@each $name, $value in vars.$gray {
  .#{$name} {
    color: $value;
  }
}
@each $name, $value in vars.$gray {
  .i#{$name} {
    color: $value !important;
  }
}
@each $name, $value in vars.$gray {
  .#{$name}-bg {
    background-color: $value;
  }
}
@each $name, $value in vars.$gray {
  .i#{$name}-bg {
    background-color: $value !important;
  }
}

// New Design System Colors
@each $name, $value in vars.$vfi-blue {
  .#{$name} {
    color: $value;
  }
}
@each $name, $value in vars.$vfi-blue {
  .i#{$name} {
    color: $value !important;
  }
}
@each $name, $value in vars.$vfi-blue {
  .#{$name}-bg {
    background-color: $value;
  }
}
@each $name, $value in vars.$vfi-blue {
  .i#{$name}-bg {
    background-color: $value !important;
  }
}

@each $name, $value in vars.$vfi-light-mode {
  .#{$name} {
    color: $value;
  }
}
@each $name, $value in vars.$vfi-light-mode {
  .i#{$name} {
    color: $value !important;
  }
}
@each $name, $value in vars.$vfi-light-mode {
  .#{$name}-bg {
    background-color: $value;
  }
}
@each $name, $value in vars.$vfi-light-mode {
  .i#{$name}-bg {
    background-color: $value !important;
  }
}

@each $name, $value in vars.$vfi-dark-mode {
  .#{$name} {
    color: $value;
  }
}
@each $name, $value in vars.$vfi-dark-mode {
  .i#{$name} {
    color: $value !important;
  }
}
@each $name, $value in vars.$vfi-dark-mode {
  .#{$name}-bg {
    background-color: $value;
  }
}
@each $name, $value in vars.$vfi-dark-mode {
  .i#{$name}-bg {
    background-color: $value !important;
  }
}

@each $name, $value in vars.$vfi-green {
  .#{$name} {
    color: $value;
  }
}
@each $name, $value in vars.$vfi-green {
  .i#{$name} {
    color: $value !important;
  }
}
@each $name, $value in vars.$vfi-green {
  .#{$name}-bg {
    background-color: $value;
  }
}
@each $name, $value in vars.$vfi-green {
  .i#{$name}-bg {
    background-color: $value !important;
  }
}

@each $name, $value in vars.$vfi-red {
  .#{$name} {
    color: $value;
  }
}
@each $name, $value in vars.$vfi-red {
  .i#{$name} {
    color: $value !important;
  }
}
@each $name, $value in vars.$vfi-red {
  .#{$name}-bg {
    background-color: $value;
  }
}
@each $name, $value in vars.$vfi-red {
  .i#{$name}-bg {
    background-color: $value !important;
  }
}

@each $name, $value in vars.$vfi-yellow {
  .#{$name} {
    color: $value;
  }
}
@each $name, $value in vars.$vfi-yellow {
  .i#{$name} {
    color: $value !important;
  }
}
@each $name, $value in vars.$vfi-yellow {
  .#{$name}-bg {
    background-color: $value;
  }
}
@each $name, $value in vars.$vfi-yellow {
  .i#{$name}-bg {
    background-color: $value !important;
  }

  @each $name, $value in vars.$vfi-navy {
    .#{$name} {
      color: $value;
    }
  }
  @each $name, $value in vars.$vfi-navy {
    .i#{$name} {
      color: $value !important;
    }
  }
  @each $name, $value in vars.$vfi-navy {
    .#{$name}-bg {
      background-color: $value;
    }
  }
  @each $name, $value in vars.$vfi-navy {
    .i#{$name}-bg {
      background-color: $value !important;
    }
  }
}
