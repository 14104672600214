@use "sass:map";
@use 'libs/styles/vars';

// This .scss loop will create "margin helpers" and "padding helpers" for use in VFI.
// It will generate several classes such as:
// .mr-10 which gives margin-right 10. rem.
// .pb-5 gives PADDING to the BOTTOM of 1.5625 rem
// The classes generated will be break-point aware classes.
// .mr-sm-10 which gives margin-right 10. rem but only activate on breakpoints greater than sm.
// The first letter is "m" or "p" for MARGIN or PADDING
// Second letter is "t", "b", "l", or "r" for TOP, BOTTOM, LEFT, or RIGHT
// Third/Fourth letter is the number of spacing in rem.
// Adjust the amounts generated by editing the $spacers or grid-breakpoints variable in vars scss file.
// affix !important can be added by appending i to any class names

@each $breakName, $breakValue in vars.$grid-breakpoints {
  @each $sideName, $sideValue in vars.$sides {
    @for $i from 0 through 10 {
      $property: if($sideName == '', '', -#{$sideValue});
      $space: map.get(vars.$spacers, $i);
      $selector: '';

      @if $breakName != '' {
        $selector: #{$sideName}-#{$breakName}-#{$i};
      } @else {
        $selector: #{$sideName}-#{$i};
      }

      @if $breakName != '' {
        @media (min-width: $breakValue) {
          .m#{$selector} {
            margin#{$property}: #{$space};
          }

          .p#{$selector} {
            padding#{$property}: #{$space};
          }
        }
      } @else {
        .m#{$selector} {
          margin#{$property}: #{$space};
        }

        .p#{$selector} {
          padding#{$property}: #{$space};
        }
      }
    }
  }
}

@each $breakName, $breakValue in vars.$grid-breakpoints {
  @each $sideName, $sideValue in vars.$sides {
    @for $i from 0 through 10 {
      $property: if($sideName == '', '', -#{$sideValue});
      $space: map.get(vars.$spacers, $i);
      $selector: '';

      @if $breakName != '' {
        $selector: #{$sideName}-#{$breakName}-#{$i};
      } @else {
        $selector: #{$sideName}-#{$i};
      }

      @if $breakName != '' {
        @media (min-width: $breakValue) {
          .im#{$selector} {
            margin#{$property}: #{$space} !important;
          }

          .ip#{$selector} {
            padding#{$property}: #{$space} !important;
          }
        }
      } @else {
        .im#{$selector} {
          margin#{$property}: #{$space} !important;
        }

        .ip#{$selector} {
          padding#{$property}: #{$space} !important;
        }
      }
    }
  }
}
