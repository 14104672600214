@use '../base/mixins';
@use '../bootstrap-custom';
@use '../vars';
@use '../vendors/bootstrap/mixins/breakpoints';

/* header styles */
#header {
  background: vars.$body-bg;
  height: 60px;
  padding: 10px 0;

  @include breakpoints.media-breakpoint-up(md) {
    height: 78px;
    padding: 15px 0;
  }

  @include breakpoints.media-breakpoint-up(lg) {
    height: 108px;
    padding: 40px 0 20px;
  }

  .logo {
    margin: 0 10px 0 0;
    width: 115px;

    @include breakpoints.media-breakpoint-up(md) {
      margin: 5px 25px 0 0;
      width: 175px;
    }

    @include breakpoints.media-breakpoint-up(tv) {
      margin: 7px 45px 0 0;
      width: 185px;
    }
  }
}
/* page logo styles */
.logo {
  img {
    @include mixins.responsive-image;
  }
}
/* search form styles */
.search-form {
  // [TODO] Not sure why, but it's not happy unless I put some type of WIDTH here
  display: block;
  // ^^ Needs more investigation
  justify-content: flex-end;
  margin: 0 0 0 10px;
  width: 70%;

  form {
    background: none;
    box-shadow: none;
    left: 0;
    padding: 0;
    position: static;
    right: 0;
    top: 0;
    z-index: 999;

    .btn-close {
      right: 15px;
    }
  }

  .input-holder {
    margin: 0;
    position: relative;
  }

  .filter-bars {
    font-size: 18px;
    left: 15px;
    line-height: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .cross {
    font-size: 18px;
    line-height: 1;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  .form-control {
    background: vars.$gray-10;
    font-size: bootstrap-custom.$input-font-size-lg !important;
    font-weight: vars.$font-weight-normal;
    line-height: bootstrap-custom.$input-line-height-lg !important;
    padding: 12px 16px 12px 43px;
  }
}

// *** [NOTE] taken directly from Vue component style
// Should be cleaned up in scss format
.chip-container {
  align-content: space-between;
  display: flex;
  flex-wrap: wrap;
  min-height: 34px;
}

.chip-container .chip {
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 3px;
  display: flex;
  margin: 4px;
  padding: 0 4px;
}

.chip-container .chip i {
  cursor: pointer;
  margin-left: 8px;
  opacity: 0.56;
}

.chip-container input {
  border: 0;
  flex: 1 1 auto;
  outline: none;
  padding: 4px;
  width: 30px;
}

.extra-margin {
  margin-left: 47px !important;
}
// ^^^ *** [NOTE] taken directly from Vue component style

/* users dropdown styles */
.users-dropdown {
  flex-shrink: 0;
  margin: 0 0 0 5px;
  position: relative;

  @include breakpoints.media-breakpoint-up(lg) {
    min-width: 210px;
  }

  .opener {
    @include breakpoints.media-breakpoint-up(lg) {
      display: none;
    }

    &:after {
      @include mixins.icon('\e906');
      font-size: 6px;
      position: absolute;
      right: 3px;
      top: 9px;

      @include breakpoints.media-breakpoint-up(md) {
        font-size: 7px;
        top: 12px;
      }
    }
  }

  .users {
    box-shadow: 0 0 10px rgba(vars.$black, 0.5);
    flex-flow: column nowrap;
    left: 50% !important;
    margin: 12px 0 0;
    min-width: inherit;
    opacity: 0;
    padding: 10px 10px 14px;
    top: 100% !important;
    transform: translateX(-50%) !important;
    @extend %transition !optional;
    visibility: hidden;
    width: 56px;

    @include breakpoints.media-breakpoint-up(lg) {
      background: none;
      box-shadow: none;
      flex-flow: row nowrap;
      margin: 0;
      opacity: 1;
      padding: 0;
      position: static !important;
      transform: none !important;
      visibility: visible;
    }

    &.show {
      opacity: 1;
      visibility: visible;
    }

    &:after {
      right: 50%;
      transform: translateX(50%);

      @include breakpoints.media-breakpoint-up(lg) {
        display: none;
      }
    }

    li {
      margin: 0 0 -4px;

      @include breakpoints.media-breakpoint-up(md) {
      }

      @include breakpoints.media-breakpoint-up(lg) {
        margin: 0 0 0 -4px;
      }
    }

    .btn-circle {
      box-shadow: 0 0 8px rgba(vars.$black, 0.5);

      @include breakpoints.media-breakpoint-up(lg) {
        box-shadow: none;
      }
    }
  }
}
/* users styles */
.users {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  margin: 0;
  padding: 0 0 0 10px;

  li {
    margin: 0 0 0 -4px;

    a {
      @include mixins.size(36px);
      border-radius: 100%;
      display: block;
      overflow: hidden;

      img {
        @include mixins.responsive-image;
      }

      i {
        font-size: 16px;
      }
    }
  }
}
