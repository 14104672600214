@use 'libs/styles/vars';

@each $name, $value in vars.$borders {
  .#{$name} {
    border: 1px solid $value;
  }
}

@each $name, $value in vars.$borders-errors {
  .#{$name} {
    border: 1px solid $value;
  }
}

@each $name, $value in vars.$borders {
  .i#{$name} {
    border: 1px solid $value !important;
  }
}

@each $name, $value in vars.$borders {
  .right-#{$name} {
    border-right: 1px solid $value;
  }
}

@each $name, $value in vars.$borders {
  .iright-#{$name} {
    border-right: 1px solid $value;
  }
}

@each $name, $value in vars.$borders {
  .left-#{$name} {
    border-left: 1px solid $value;
  }
}

@each $name, $value in vars.$borders {
  .ileft-#{$name} {
    border-left: 1px solid $value;
  }
}

@each $name, $value in vars.$borders {
  .top-#{$name} {
    border-top: 1px solid $value;
  }
}

@each $name, $value in vars.$borders {
  .itop-#{$name} {
    border-top: 1px solid $value;
  }
}

@each $name, $value in vars.$borders {
  .bottom-#{$name} {
    border-bottom: 1px solid $value;
  }
}

@each $name, $value in vars.$borders {
  .ibottom-#{$name} {
    border-bottom: 1px solid $value;
  }
}

.border-radius-4 {
  border-radius: 4px;
}

.iborder-radius-4 {
  border-radius: 4px !important;
}
