@use 'libs/styles/vendors/bootstrap/mixins/breakpoints';
@use 'libs/styles/vars';

.vfi-button-defaults {
  // sass-lint:disable no-color-literals, no-transition-all
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  background-image: none;
  border: 2px solid transparent;
  border-radius: 4px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: 32px;
  line-height: 24px;
  padding: 0 12px;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  transition: all 0.1s ease;
  user-select: none;
  white-space: nowrap;
}

.vfi-button-dark-mode {
  color: vars.$DM00;
}

.vfi-button-defaults.large {
  height: 40px;
}

.vfi-button-defaults:disabled {
  color: vars.$DM100;
  background-color: rgba(81, 95, 112, 0.4);
  cursor: not-allowed;
}

.vfi-button-defaults.vfi-button-small {
  font-size: 14px;
  height: 24px;
  line-height: 22px;
  padding: 0 7px;
  i {
    font-size: 12px;
  }
}

.vfi-button-icon-defaults {
  border-radius: 50%;
  font-size: 14px;
  height: 32px;
  padding-top: 4px;
  width: 32px;
}

.vfi-button-icon-defaults.vfi-button-large {
  height: 40px;
  width: 40px;
  i {
    font-size: 16px;
  }
}

.vfi-button-icon-defaults.vfi-button-small {
  height: 24px;
  padding-top: 1px;
  width: 24px;
  i {
    font-size: 10px;
  }
}

.vfi-button-defaults:not(.disabledBtn):active {
  transform: scale(0.95);
}

.block-button {
  display: block;
  width: 100%;
}

.danger-btn {
  background: vars.$R100;
  background-color: vars.$R100;

  &:not(.disabledBtn):hover {
    background: vars.$R300;
    background-color: vars.$R300;
  }

  &:focus {
    background: vars.$R100;
    background-color: vars.$R100;
    border: 2px solid vars.$B40;
  }

  &:not(.disabledBtn):active {
    background: vars.$R500;
    background-color: vars.$R500;
  }
}

.primary-btn {
  background: vars.$B100;
  background-color: vars.$B100;

  &:not(.disabledBtn):hover {
    background: vars.$B300;
    background-color: vars.$B300;
  }

  &:focus {
    background: vars.$B100;
    background-color: vars.$B100;
    border: 2px solid vars.$B40;
  }

  &:not(.disabledBtn):active {
    background: vars.$B500;
    background-color: vars.$B500;
  }
}

.secondary-btn {
  background: vars.$DM500;
  background-color: vars.$DM500;

  &:not(.disabledBtn):hover {
    background: vars.$DM300;
    background-color: vars.$DM300;
  }

  &:focus {
    background: vars.$DM500;
    background-color: vars.$DM500;
    border: 2px solid vars.$B40;
  }

  &:not(.disabledBtn):active {
    background: vars.$DM500;
    background-color: vars.$DM500;
  }
}

.tertiary-btn {
  background: transparent;
  background-color: transparent;

  &:not(.disabledBtn):hover {
    background: vars.$DM300;
    background-color: vars.$DM300;
  }

  &:focus {
    background: transparent;
    background-color: transparent;
    border: 2px solid vars.$B40;
  }

  &:not(.disabledBtn):active {
    background: vars.$DM500;
    background-color: vars.$DM500;
  }

  &:disabled {
    background: transparent;
  }
}

.link-btn {
  background: transparent;
  background-color: transparent;
  color: vars.$B60;

  &:not(.disabledBtn):hover {
    color: vars.$B60;
    text-decoration: underline;
  }

  &:focus {
    color: vars.$B60;
    border: 2px solid vars.$B40;
  }

  &:not(.disabledBtn):active {
    color: vars.$B100;
    text-decoration: underline;
  }

  &:disabled {
    background: transparent;
  }
}

.vfi-btn-circle {
  border: 1px solid transparent;
  border-radius: 100%;
  display: block;
  height: 36px;
  min-width: inherit;
  padding: 5px;
  position: relative;
  width: 36px;
}

.block-button-sm {
  @include breakpoints.media-breakpoint-down(sm) {
    display: block;
    width: 100%;
  }
}

// For table toggle styles
.dark-btn {
  background: vars.$gray-10;
  background-color: vars.$gray-10;
  border-width: 0;
  color: vars.$neutral-1;
}

.dark-btn:hover {
  background: vars.$gray-10;
  background-color: vars.$gray-10;
  border-width: 0;
  color: vars.$neutral-1;
}

.dark-btn:focus {
  background: vars.$gray-10;
  background-color: vars.$gray-10;
  border: 1px solid vars.$neutral-1;
  border-color: vars.$neutral-1;
  color: vars.$neutral-1;
}

.dark-btn:active {
  background: vars.$gray-10;
  background-color: vars.$gray-10;
  border: 1px solid vars.$gray-10;
  box-sizing: border-box;
}

.dark-btn:disabled {
  background: vars.$neutral-2;
  background-color: vars.$neutral-2;
  border: 1px solid vars.$neutral-6;
  box-sizing: border-box;
  color: vars.$neutral-6;
  cursor: not-allowed;
}
