@use 'libs/styles/vars';

// list reset
%listreset {
  list-style: none;
  margin: 0;
  padding: 0;
}

// clearfix
%clearfix {
  &:after {
    clear: both;
    content: '';
    display: block;
  }
}

.clearfix {
  @extend %clearfix;
}

// transition
%transition {
  transition: all 0.3s ease-in-out;
}

// justify nav
%justify {
  font-size: 1px;
  line-height: 0;
  text-align: justify;
  > * {
    display: inline-block;
    font-size: vars.$rem-base;
    line-height: vars.$line-height-base;
    text-align: left;
    vertical-align: top;
  }

  &:after {
    content: '';
    display: inline-block;
    vertical-align: top;
    width: 100%;
  }
}

/// * Helper class to truncate and add ellipsis to a string too long for it to fit
/// * on a single line.
/// * 1. Prevent content from wrapping, forcing it on a single line.
/// * 2. Add ellipsis at the end of the line.

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis; /* 2 */
  white-space: nowrap; /* 1 */
}
