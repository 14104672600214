@use 'sass:color';
@use 'libs/styles/vars';

@use 'vendors/bootstrap/functions';
// @import "vendors/bootstrap/variables";
@use 'vendors/bootstrap/mixins' as mixins2;
@forward 'vendors/bootstrap/utilities/_align.scss';

@use 'base/mixins';
@forward 'base/helpers';

@forward 'base/typography';

@use 'bootstrap-custom';

@forward 'layout/header';
@forward 'layout/common';
@forward 'layout/footer';

@use '@fortawesome/fontawesome-pro/scss/fontawesome';
@use '@fortawesome/fontawesome-pro/scss/regular';
@use '@fortawesome/fontawesome-pro/scss/solid';
@use '@fortawesome/fontawesome-pro/scss/light';
@use '@fortawesome/fontawesome-pro/scss/duotone';

@forward 'datatable.scss';
@use 'tokens.scss';
@use 'dark-mode.scss';
@forward 'ng-select-custom-theme';
@use 'vendors/bootstrap/mixins/breakpoints';

@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

@import '~mapbox-gl/dist/mapbox-gl.css';

// Body

body {
  background-color: vars.$gray-9;
  color: vars.$body-color;
  min-width: vars.$base-min-width;
}

scrollbar {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: vars.$neutral-2;
  border-radius: 15px;
}

::-webkit-scrollbar:horizontal {
  height: 3px;
}

::-webkit-scrollbar:vertical {
  width: 5px;
}

.dashboard-wrapper {
  height: 100%;
  padding: 0 1.875rem !important;
}

::-webkit-scrollbar {
  background-color: transparent;
}

// General styles

input,
textarea {
  border: 1px solid transparent !important;
  border-radius: 4px;
  outline: none;
  width: 100%;
}

input:focus,
input:not(.ng-valid):hover,
.ant-picker:hover {
  border: 1px solid vars.$neutral-1 !important;
}

input:disabled:hover,
textarea:disabled:hover,
.ant-picker-disabled {
  border: 1px solid transparent !important;
}

.z-index-max {
  z-index: 102;
}

.description-field {
  height: 100px !important;
}

.disabled-input {
  input {
    background: vars.$gray-8 !important;
    color: vars.$neutral-5 !important;
    border: 1px solid transparent;
  }

  .ant-input {
    background: vars.$gray-8 !important;
    color: vars.$neutral-5 !important;
  }
}

.disabled-user-label {
  background: vars.$gray-8 !important;
  color: vars.$neutral-5 !important;
  border: 1px solid transparent;
  cursor: default;
}

hr {
  border: 0;
  border-top: 1px solid vars.$gray-600;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.iw-100 {
  width: 100% !important;
}

.w-sm-100 {
  @include breakpoints.media-breakpoint-down(sm) {
    width: 100% !important;
  }
}

.w-md-100 {
  @include breakpoints.media-breakpoint-down(md) {
    width: 100% !important;
  }
}

.list-unstyled {
  list-style: none;
  padding-left: 0;
}

.tooltip-title,
.tooltip-content {
  color: vars.$neutral-1;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
}

.tooltip-content {
  font-weight: normal;
}

.flex-auto {
  flex: 1 1 auto;
}

.nowrap {
  flex-wrap: nowrap !important;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.alarmTableDimensions {
  max-width: calc(100vw - 304px) !important;
}

.contentHeight {
  height: calc(100vh - 151px) !important;
}

.adminUserTileHeight {
  height: calc(100vh - 300px) !important;
}

.userTileHeight {
  height: calc(100vh - 256px) !important;
}

.subContentHeight {
  height: calc(100vh - 275px) !important;
}

.alarmDetailsTabContentHeight {
  height: calc(100vh - 335px) !important;
}

.detailsTabHeight {
  height: calc(100vh - 245px) !important;
}

.changeHistoryHeight {
  height: calc(100vh - 321px) !important;
}

.subContentReportHeight {
  height: calc(80vh - 5px) !important;
}

.primaryNavHeight {
  height: calc(100vh - 200px) !important;
}

.drawerContentHeight {
  height: calc(100vh - 215px) !important;
}

.alarmFrequencyIframeHeight,
.alarmInstanceReportHeight {
  height: calc(100vh - 149px) !important;
}

.userContentHeight {
  height: calc(100vh - 215px) !important;
}

.mobileContentHeight {
  height: calc(100vh - 75px) !important;
}

.analyticsReportHeight {
  height: calc(100vh - 145px) !important;
}

.mobileWorkContentHeight {
  height: calc(100vh - 150px) !important;
}

.permissionsTabContentHeight {
  height: calc(100vh - 385px) !important;
}

.groupByContentHeight {
  height: calc(100vh - 340px) !important;
}

.alarmsTableHeight {
  height: calc(100vh - 156px) !important;
}

.dashboardWrapper {
  overflow: auto;
  width: calc(100vw - 292px) !important;
}

.kpiMetricHeight {
  height: calc(100vh - 350px) !important;
}

.chartWrapper {
  height: calc(100vh - 302px) !important;
}

.mobileCreateWorkHeight {
  height: calc(100vh - 190px) !important;
}

.alarmDetailsHeight {
  height: calc(100vh - 225px) !important;
}

.alarmDetailsModalHeight {
  height: calc(100vh - 50px) !important;
}

.alarmDetailsMobileHeight {
  height: calc(100vh - 10px) !important;
}

.modalBodyHeight {
  height: calc(100vh - 550px) !important;
}

.alarmTitleWidth {
  width: calc(100vw - 1130px) !important;
}

.alarmTitleWidthNoActionMenu {
  width: calc(100vw - 1020px) !important;
}

.alarmTitleSlideoutWidth {
  position: relative;
  width: calc(100% - 30%) !important;
}

.analyticsTableWidth {
  width: calc(100vw - 305px) !important;
}

.analyticsTableHeight {
  height: calc(100vh - 223px) !important;
}

.report-title {
  height: 49px;

  h1 {
    font-size: 20px !important;
  }
}

.sisense-report-title {
  height: 48px;

  h1 {
    font-size: 16px !important;
  }
}

.page-title-wrapper {
  width: 175px;
  margin-right: 65px;
}

.page-logo {
  display: flex;
  align-items: center;
  font-size: 22px;

  text-decoration: none !important;

  i {
    margin-right: 10px;
  }

  p {
    margin-bottom: 0;
  }

  h1 {
    font-size: 22px;
    margin-bottom: 0;
  }
}

.pointer-none {
  pointer-events: none;
}

.pointer-all {
  pointer-events: all;
}

.priority-5 {
  color: vars.$red-6 !important;
}

.priority-4 {
  color: vars.$orange-4 !important;
}

.priority-3 {
  color: vars.$yellow-4 !important;
}

.priority-2 {
  color: vars.$gray-5 !important;
}

.priority-1 {
  color: vars.$gray-2 !important;
}

.priority-0 {
  color: vars.$neutral-1 !important;
}

.priority-5-font {
  color: vars.$neutral-1 !important;
}

.priority-4-font {
  color: vars.$priority-4-font !important;
}

.priority-3-font {
  color: vars.$priority-3-font !important;
}

.priority-2-font {
  color: vars.$neutral-1 !important;
}

.priority-1-font {
  color: vars.$neutral-1 !important;
}

.priority-0-font {
  color: vars.$neutral-10 !important;
}

.priority-bg {
  background-color: vars.$gray-9;
}

.priority-5-bg {
  background-color: vars.$red-6 !important;
  border: 1px solid vars.$red-6;

  &:hover {
    i {
      color: vars.$neutral-1;
    }
  }
}

.priority-4-bg {
  background-color: vars.$orange-4 !important;
  border: 1px solid vars.$orange-4;

  &:hover {
    i {
      color: vars.$priority-4-font;
    }
  }
}

.priority-4-action-bar-bg {
  background-color: vars.$orange-6 !important;
  border: 1px solid vars.$orange-6;

  &:hover {
    i {
      color: vars.$neutral-1;
    }
  }
}

.priority-3-bg {
  background-color: vars.$yellow-4 !important;
  border: 1px solid vars.$yellow-4;

  &:hover {
    i {
      color: vars.$priority-4-font;
    }
  }
}

.priority-3-action-bar-bg {
  background-color: vars.$yellow-6 !important;
  border: 1px solid vars.$yellow-6;

  &:hover {
    i {
      color: vars.$neutral-10;
    }
  }
}

.priority-2-bg {
  background-color: vars.$gray-5 !important;
  border: 1px solid vars.$gray-5;

  &:hover {
    i {
      color: vars.$neutral-1;
    }
  }
}

.priority-1-bg {
  background-color: vars.$gray-2 !important;
  border: 1px solid vars.$gray-2;

  &:hover {
    i {
      color: vars.$neutral-10;
    }
  }
}

.priority-0-bg {
  background-color: vars.$neutral-1 !important;
  border: 1px solid vars.$neutral-1;

  &:hover {
    i {
      color: vars.$neutral-10;
    }
  }
}

.white {
  color: vars.$white;
}

.yellow {
  color: vars.$yellow-6;
}

.green {
  color: vars.$green-6;
}

.vertical-center-modal {
  align-items: center;
  display: flex;
  justify-content: center;
}

.vertical-center-modal .ant-modal {
  top: 0;
}

.new-entity-modal .ant-modal-body {
  background: vars.$gray01;
}

.lens-description-modal {
  .ant-modal-body {
    height: auto;
    max-height: calc(100vh - 440px);
    overflow: scroll;
  }
}

.notification-template-modal {
  .ant-modal-body {
    height: 880px;
  }
}

.border-100 {
  border: 1.5px solid vars.$gray-100;
}

.border-200 {
  border: 1.5px solid vars.$gray-200;
}

.border-300 {
  border: 1.5px solid vars.$gray-300;
}

.border-400 {
  border: 1.5px solid vars.$gray-400;
}

.border-500 {
  border: 1.5px solid vars.$gray-500;
}

.border-600 {
  border: 1.5px solid vars.$gray-600;
}

.border-700 {
  border: 1.5px solid vars.$gray-700;
}

.border-800 {
  border: 1.5px solid vars.$gray-800;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-progress {
  cursor: progress;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-default {
  cursor: default !important;
}

.cursor-grab {
  cursor: grab !important;
}

.cursor-move {
  cursor: move !important;
}

// Requires inline-block or block for proper styling
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// apply ellipsis to text without a set width
// ellipsis-auto class must be applied to a parent div
// text must be child span element
.ellipsis-auto {
  position: relative;

  &:before {
    content: '&nbsp;';
    visibility: hidden;
  }

  span {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.overflow-hidden {
  overflow: hidden !important;
}

.required-text {
  color: vars.$orange-5;

  span {
    border-bottom: 1px solid vars.$orange-5;
  }

  &:hover span {
    border-bottom: 1px solid vars.$neutral-1;
  }
}

.required-text-active {
  color: vars.$neutral-1;

  span {
    border-bottom: 1px solid vars.$neutral-1;
  }
}

.caret-wrapper {
  position: relative;

  .caret-down {
    color: vars.$gray-1;
    font-size: vars.$regular-16;
    font-weight: 900;
    position: absolute;
    right: 14px;
    top: 17px;
    z-index: 1;
  }
}

// ant design styles

.ant-tooltip-content {
  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: $DM400 !important;
  }

  .ant-tooltip-inner {
    background: vars.$DM400 !important;
  }
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.ant-menu-item,
.ant-menu-submenu {
  background-color: vars.$gray-8 !important;
  border-bottom: 1px solid vars.$gray-9 !important;
  font-size: 15px;
  font-weight: bold;
  margin: 0 !important;
}

.ant-menu-item:hover {
  background: vars.$gray-10 !important;
}

.ant-menu-inline-collapsed,
.ant-menu-inline-collapsed .ant-menu-item,
.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title {
  width: 50px !important;
}

.ant-menu-submenu-title {
  border-bottom: 1px solid vars.$gray-9;

  margin: 0 !important;
  padding: 0 !important;

  .inner-submenu-span {
    outline: none;
    padding-left: 10px;
    padding-right: 16px;
  }

  .inner-submenu-span:hover {
    background-color: vars.$gray-10;
  }
}

.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  display: block !important;
}

.ant-collapse-header {
  font-weight: 500;
}

.ant-menu-item {
  margin: 0 !important;
  padding-left: 10px !important;
  padding-right: 16px !important;
}

.ant-menu-item a::before {
  pointer-events: none;
}

nz-select {
  border: 1px solid transparent !important;

  input:focus {
    border: 0 !important;
  }
}

.ant-picker-active-bar {
  background: transparent !important;
}

.submenu-border-spacer {
  border-left: 3px solid transparent;
}

.activeLink,
.subMenuActive {
  background: vars.$gray-10 !important;
  border-left: 3px solid vars.$white !important;
  pointer-events: none;
}

.menu-header {
  background: vars.$gray-9 !important;
  cursor: default !important;

  &:hover {
    background: vars.$gray-9 !important;
  }
}

.inline-menu-header {
  background: vars.$gray-9 !important;
  cursor: pointer !important;
  border-top: 1px solid vars.$gray-8;

  &:hover {
    background: vars.$gray-9 !important;
  }

  .ant-menu-submenu-title {
    padding-left: 24px !important;
  }

  .ant-menu-submenu-arrow {
    display: block !important;
  }
}

.ant-badge-count,
.ant-select-multiple .ant-select-selection-item {
  background: vars.$blue-6 !important;
  border-radius: 2px !important;
  color: vars.$neutral-1 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.ant-select-multiple .ant-select-selection-item {
  background: vars.$gray-7 !important;
  color: vars.$gray-1 !important;
  height: 20px !important;
  line-height: 20px !important;
  margin-left: 4px !important;
  margin-top: 23px !important;
}

.no-placeholder.ant-select-multiple {
  .ant-select-selection-item {
    margin-top: 0 !important;
  }
}

.ant-select-selection-item-remove {
  display: none !important;
}

.ant-select-item-option-selected {
  background: vars.$gray-10 !important;

  .ant-select-selected-icon {
    color: vars.$neutral-1 !important;
    font-size: vars.$regular-16;
    font-weight: 900;
  }
}

.aboveThresholdOption .ant-select-dropdown {
  .ant-select-item {
    padding: 0 !important;
  }
}

.ant-drawer-body {
  padding: 0 !important;
}

.ant-drawer-header {
  padding-bottom: 0 !important;
  border-bottom: none !important;
}

.ant-drawer-header-title {
  justify-content: end !important;
}

.ant-table-thead tr th {
  background: vars.$gray-8 !important;

  &:hover {
    background: vars.$gray-7 !important;
  }
}

.ant-table-thead tr th.all-selected {
  background: vars.$gray-10 !important;
}

.ant-picker-panel-container {
  background-color: vars.$DM600 !important;
  border: 1px solid dark-mode.$border_02 !important;
  box-shadow: none !important;
}

.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: dark-mode.$border_02 !important;
}

.ant-picker-range-arrow {
  display: none !important;
}

.calendar-input-none {
  .ant-calendar-input-wrap {
    display: none;
  }
}

.date-range-picker-inline-calendar {
  left: 2px !important;
}

.ant-picker-range {
  width: 100% !important;

  input {
    text-align: center !important;
  }
}

.ant-calendar-picker-input,
input,
textarea {
  color: vars.$neutral-1 !important;
  font-size: vars.$regular-16 !important;
  font-weight: 500 !important;
}

input::placeholder,
textarea::placeholder {
  color: vars.$neutral-7;
  font-size: regular-16;
  font-weight: 500;
}

nz-date-picker {
  input::placeholder {
    color: vars.$neutral-7 !important;
  }
}

.ant-calendar-picker-input::placeholder {
  color: vars.$neutral-7 !important;
  line-height: 16px !important;
  font-size: vars.$regular-16 !important;
  font-weight: normal !important;
  text-transform: none !important;
}

.ant-calendar-picker-input:disabled {
  border: 1px solid vars.$gray-10 !important;
}

.ant-picker-dropdown-range {
  right: 10px !important;
}

input {
  font-size: vars.$regular-16;
  height: 50px;
}

textarea {
  padding-top: 22px !important;
}

.ant-table-header {
  overflow: hidden !important;
}

.ant-table-cell {
  height: 57px !important;
}

.ant-calendar-picker-icon {
  color: vars.$gray-500 !important;
}

.ant-switch {
  background-color: vars.$gray-6 !important;
  height: 22px;
  min-width: 38px !important;

  .ant-switch-handle::before {
    background-color: vars.$gray-5 !important;
  }
}

.ant-switch-checked {
  background-color: vars.$green-7 !important;

  .ant-switch-handle::before {
    background-color: vars.$neutral-1 !important;
  }
}

.error-toggle .ant-switch-checked {
  background-color: vars.$red-6 !important;
}

.ant-switch::after {
  background-color: vars.$white !important;
  height: 15px !important;
  width: 15px !important;
}

.ant-empty-normal {
  color: vars.$white !important;
}

.ant-modal {
  @include breakpoints.media-breakpoint-down(sm) {
    margin: 0 !important;
    max-width: 100vw !important;
    padding: 0 !important;
    position: fixed !important;
  }
}

.ant-modal-body {
  padding: 24px !important;
}

.modal-body {
  padding: 0 !important;
}

.ant-modal-content,
.ant-modal-footer {
  background: vars.$gray-8 !important;
}

.ant-modal-content {
  @include breakpoints.media-breakpoint-down(sm) {
    @include mixins.fixed-full-page;
  }
}

.asset-modal {
  .ant-modal-content {
    background: vars.$DM700 !important;
  }
}

.asset-options-popover {
  .ant-popover-inner {
    background: vars.$DM400 !important;
  }
}

.notification-modal {
  width: 839px !important;
  .ant-modal-body {
    max-height: 700px !important;
    overflow: auto;
  }
}

.existWorkItem {
  border-radius: 4px;
  &:hover {
    background: dark-mode.$field_background_hover_01;
  }
}

.ant-modal-header {
  background: vars.$gray-7 !important;
  border-bottom: 0 !important;
  font-size: regular-20;
  padding: 24px 68px 24px 30px !important;
}

// Dark Mode Modal
.dark-modal {
  .ant-modal-header,
  .ant-modal-body,
  .ant-modal-footer {
    background: vars.$DM600 !important;
  }

  .ant-modal-header {
    padding-bottom: 0 !important;
  }
}

.dark-slideout {
  .ant-drawer-content {
    background: vars.$DM600 !important;
  }
}

.ant-modal-title {
  font-size: 20px !important;
}

.ant-modal-close-x {
  align-items: center !important;
  color: vars.$gray-500 !important;
  display: flex !important;
  font-size: 20px !important;
  height: 68px !important;
}

.ant-btn:hover {
  border-color: transparent !important;
  color: vars.$white !important;
}

.required-field,
.required-field:hover,
.required-field:focus,
.required-field:active,
.required-field.ant-btn:hover {
  border: 1px solid vars.$orange-5 !important;
  border-radius: 4px;
}

.required-select {
  nz-select,
  ng-select {
    border: 1px solid vars.$orange-5 !important;
    border-radius: 4px;
  }
}

.ng-select {
  .ng-clear-wrapper {
    background-color: transparent;
    color: vars.$DM00;
    left: 0;
    bottom: 3px;
    position: relative;

    .ng-clear {
      color: vars.$DM00;
      font-size: 14px;
    }
  }
}

.vfi-ng-select {
  &.readOnly {
    .ng-select-container {
      pointer-events: none;
      background-color: transparent !important;
    }
  }

  .ng-select-container {
    background-color: dark-mode.$field_background_01 !important;
    border: 2px solid dark-mode.$border_02 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: dark-mode.$text_primary !important;
    height: 40px !important;

    &:hover {
      background: dark-mode.$field_background_hover_01 !important;
    }

    &:focus {
      background: dark-mode.$field_background_hover_01 !important;
      border: 2px solid dark-mode.$border_focused !important;
    }

    &.disabled {
      background: dark-mode.$field_background_disabled !important;
      border: dark-mode.$border_disabled !important;
      color: dark-mode.$text_disabled !important;
    }

    .ng-placeholder {
      color: vars.$DM60 !important;
      font-weight: 400 !important;
      font-size: 14px !important;
    }
  }
}

.ant-btn:focus {
  border-color: vars.$neutral-1 !important;
  color: vars.$neutral-1 !important;
}

.ant-btn {
  &.white-btn {
    background-color: vars.$neutral-2 !important;
    border: 1px solid vars.$neutral-6 !important;
    color: vars.$neutral-6 !important;

    &:hover {
      border: 1px solid vars.$neutral-6 !important;
      color: vars.$neutral-6 !important;
    }
  }
}

.ant-checkbox-inner {
  background-color: vars.$neutral-9 !important;
  border: 1px solid vars.$gray-3 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: vars.$blue-6 !important;

  &::after {
    border-bottom-color: vars.$blue-6 !important;
    border-left-color: vars.$blue-6 !important;
    border-right-color: vars.$blue-6 !important;
    border-top-color: vars.$blue-6 !important;
  }
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: transparent !important;

  &::after {
    border-bottom-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
  }
}

.ant-checkbox-disabled + span {
  color: vars.$DM60 !important;
  font-size: 14px !important;
}

.ant-dropdown-menu {
  background-color: vars.$gray-7 !important;
  border-radius: 0 !important;
  padding: 0 !important;
}

.ant-dropdown-menu-item {
  border-bottom: 1px solid vars.$gray-9;
  font-size: vars.$regular-14 !important;

  &:hover {
    background: vars.$gray-10 !important;
  }

  a {
    color: vars.$neutral-1 !important;
  }
}

.ant-select-dropdown {
  background: vars.$gray-7 !important;
}

.ant-select-item-option:hover,
.ant-time-picker-panel-select li:hover {
  background: vars.$gray-8 !important;
}

.ant-select-dropdown-menu-item-active,
.ant-time-picker-panel-input {
  background: vars.$gray-10 !important;
}

.ant-picker {
  background: dark-mode.$field_background_01 !important;
  border: 2px solid dark-mode.$border_02 !important;
  height: 40px !important;
  font-size: 16px !important;

  &.readOnly {
    background: transparent !important;
    pointer-events: none;
  }

  .ant-picker-suffix {
    display: none !important;
  }

  .ant-picker-input {
    input {
      font-size: 16px !important;
      font-weight: 400 !important;
      color: dark-mode.$text_primary !important;

      &::placeholder {
        color: vars.$DM60 !important;
        font-weight: 400 !important;
        font-size: 14px !important;
      }
    }
  }
}

.disabled-input,
.ng-select-disabled,
.ant-select-disabled .ant-select-selector {
  background: vars.$gray-8 !important;
  border: 1px solid vars.$gray-10 !important;
  color: vars.$neutral-5 !important;
  pointer-events: none;
  cursor: default;
}

.ant-pciker-disabled {
  .ant-input-disabled::placeholder {
    color: vars.$neutral-5 !important;
  }
}

.ant-picker-disabled {
  background: transparent !important;
  border: 2px solid dark-mode.$border_02 !important;
  pointer-events: none;
  cursor: default;
}

.ant-btn span {
  width: 100%;
}

.no-footer {
  .ant-modal-footer {
    border: 0 !important;
  }

  .ant-modal-body {
    height: 95% !important;
  }
}

.ant-avatar > img {
  display: block;
  height: 100%;
  width: auto !important;
}

//  default styling for spinner
.ant-spin-dot-item {
  background-color: vars.$neutral-1 !important;
}

// Drag and Drop specific Styles below
// in-flight clone
.gu-mirror {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  filter: alpha(opacity=80);
  margin: 0 !important;
  opacity: 0.8;
  pointer-events: none;
  position: fixed !important;
  z-index: 9999 !important;
}

// high-performance display:none; helper
.gu-hide {
  left: -9999px !important;
}

// added to mirrorContainer (default = body) while dragging
.gu-unselectable {
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  -webkit-user-select: none !important;
  user-select: none !important;
}

// added to the source element while its mirror is dragged
.gu-transit {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20);
  opacity: 0.2;
}

// Ant Design overrides

.ant-select-search--inline .ant-select-search__field:focus {
  border: 0 !important;
}

.ant-select-selection-item-content {
  color: vars.$neutral-1 !important;
  font-size: regular-16;
  font-weight: 500 !important;
}

.ant-select-selector {
  height: 50px !important;
  background-color: vars.$gray-10 !important;
  border: 0 !important;
  color: vars.$neutral-1 !important;
  font-size: vars.$regular-16 !important;
  font-weight: 500 !important;
  display: flex !important;
  flex-wrap: nowrap !important;
  overflow-x: auto !important;
  overflow-y: hidden !important;
}

.ant-select-selector:focus {
  border-color: vars.$neutral-1 !important;
}

.ant-select-selector:active {
  border-color: vars.$neutral-1 !important;
}

.ant-select-selector:hover {
  border-color: vars.$neutral-1 !important;
}

.ant-select-selector::after {
  content: none !important;
}

.ant-select-open .ant-select-selector {
  border-color: vars.$neutral-1 !important;
}

.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  color: vars.$neutral-7 !important;
  font-family: 'Roboto', Arial, 'Noto Sans', sans-serif;
  font-size: 12px;
  font-variant: tabular-nums;
  font-weight: 400;
  line-height: 1.5;
}

.ant-select-selection-placeholder {
  margin-top: 5px;
}

.ant-select-allow-clear
  .ant-select-selection--single
  .ant-select-selection-selected-value {
  position: absolute;
  top: 7px;
}

atom-dropdown {
  .ant-select-selection-selected-value {
    padding-left: 5px !important;
  }
}

.ant-select-search__field {
  position: absolute;
  top: 4px;
}

.ant-select-search--inline {
  overflow: hidden;
}

.ant-checkbox-indeterminate {
  .ant-checkbox-inner {
    background-color: vars.$gray-2 !important;

    &:after {
      background-color: vars.$gray-4 !important;
    }
  }
}

.ant-checkbox-indeterminate:hover {
  .ant-checkbox-inner {
    background-color: vars.$neutral-1 !important;

    &:after {
      background-color: vars.$gray-2 !important;
    }
  }
}

.ant-checkbox-wrapper {
  span {
    font-size: vars.$regular-16 !important;
  }
}

.ant-select-arrow {
  display: none !important;
}

.ant-menu-submenu-arrow {
  display: none !important;
}

.ant-table-row {
  background-color: vars.$gray-8;
  color: vars.$neutral-1;
  height: 40px;

  &:hover {
    background-color: vars.$gray-7;
  }
}

.ant-table-body {
  border-right: 1px solid vars.$gray-9;
  border-top: 1px solid vars.$gray-9;
  th,
  td {
    border-left: 1px solid vars.$gray-9;
  }
}

.ant-pagination-item,
.ant-pagination-next,
.ant-pagination-prev,
.ant-pagination-item-link,
.ant-time-picker-panel-combobox {
  background-color: vars.$gray-7 !important;
}

.ant-pagination-item-active,
.ant-pagination-item:hover,
.ant-pagination-prev:hover,
ß.ant-pagination-next:hover .ant-pagination-item-link {
  background: vars.$gray-10 !important;
  border-color: vars.$neutral-1 !important;
}

.ant-pagination-item-active a,
.ant-pagination-item:hover a,
.ant-pagination-prev:hover a,
.ant-pagination-next:hover,
.ant-pagination-item-link {
  color: vars.$neutral-1 !important;
}

.ant-pagination-simple-pager {
  height: 30px !important;
}

.ant-pagination-disabled {
  .ant-pagination-item-link {
    background-color: vars.$gray-8 !important;

    .anticon {
      color: vars.$neutral-7 !important;
    }
  }
}

.ant-empty-image,
.ant-time-picker-input {
  height: 50px !important;
}

.ant-empty-description {
  margin-top: 25px !important;
}

.ant-drawer {
  @include breakpoints.media-breakpoint-down(lg) {
    pointer-events: all;
  }
  pointer-events: none;

  .ant-drawer-content-wrapper {
    pointer-events: all;
  }

  .ant-drawer-content-wrapper.relative-height {
    height: auto !important;
    max-height: 450px !important;
    overflow: auto !important;
  }

  .previewAlarmsDrawer {
    width: 100% !important;
    height: 100% !important;

    .ant-drawer-content {
      overflow: hidden !important;
    }
  }
}

.ant-collapse {
  border: 0 !important;

  .ant-collapse-item {
    border-bottom: 0 !important;
  }
}

.collapse-arrow {
  position: absolute;
  top: 3px;
  right: 10px;
}

.ant-calendar-disabled-cell .ant-calendar-date {
  background: vars.$gray-7 !important;
}

.ant-select-close-icon {
  color: vars.$neutral-1 !important;
}

.ant-picker-date-range-wrapper {
  .ant-picker-cell-selected {
    .ant-picker-cell-inner {
      background: vars.$gray-3 !important;
      color: vars.$gray-10 !important;
    }
  }
}

.ant-calendar-today .ant-calendar-date {
  border-color: vars.$gray-3 !important;
  color: vars.$gray-3 !important;
}

.ant-picker-range,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: vars.$gray-10 !important;
}

.radioStyle nz-radio-group {
  label.ant-radio-button-wrapper {
    background: vars.$neutral-1;
    border: 1px solid vars.$neutral-5;
    color: vars.$neutral-10;
    text-align: center;
    width: 71px;
  }

  .ant-radio-button-wrapper::before {
    background: transparent;
  }

  .ant-radio-button-wrapper-checked::before {
    background-color: transparent;
  }

  .ant-radio-button-wrapper-checked,
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):first-child {
    border-color: transparent;
    box-shadow: -1px 0 0 0 transparent;
    span {
      color: vars.$blue-6;
    }
  }
}

.ant-radio-disabled + span {
  color: vars.$gray-500 !important;
}

.ant-calendar-picker-container {
  @include breakpoints.media-breakpoint-down(sm) {
    top: 10px !important;
  }
  margin-top: 40px !important;
}

.ant-table-tbody tr td,
.ant-table-thead tr th {
  word-break: break-word;
}

nz-time-picker {
  height: 50px !important;
}

.ant-picker-focused {
  border-color: transparent !important;
}

.ant-select-selection-search-input {
  border: 0 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: vars.$neutral-5 !important;
  font-weight: bold;
}

.ant-tree {
  li {
    span {
      color: vars.$neutral-1;
    }
  }
}

.awaremanager-collapse {
  .ant-collapse-header {
    @include breakpoints.media-breakpoint-down(md) {
      background-color: vars.$DM600 !important;
    }

    padding: 0 !important;
    background-color: vars.$gray-700 !important;
  }

  .ant-collapse-content {
    @include breakpoints.media-breakpoint-down(md) {
      background-color: vars.$DM600 !important;
    }

    border-top: 0 !important;
    background-color: vars.$gray-700 !important;
  }

  .ant-select-selection-item {
    margin-top: 15px !important;
  }
}

atom-dropdown-menu,
vfi-auth-customer-selector,
molecule-details-user {
  .ant-select-selection-item {
    margin-top: 15px;
  }
}

atom-dropdown-menu {
  .no-placeholder {
    .ant-select-selection-item {
      margin-top: 0;
    }
  }
}

.drawer-class-open.ant-drawer-content-wrapper:not(.relative-height) {
  @include breakpoints.media-breakpoint-down(md) {
    width: 100% !important;
    height: 100% !important;
  }
  width: calc(100vw - 625px) !important;
}

.details-slideout-drawer,
.work-ticket-details-drawer,
.alarm-details-drawer {
  &.ant-drawer-content-wrapper:not(.relative-height) {
    width: 1050px !important;
  }

  .ant-drawer-close {
    z-index: 100;
  }
}

.alarms-slideout {
  &.ant-drawer-content-wrapper:not(.relative-height) {
    width: 1050px !important;
  }
  .ant-drawer-close {
    z-index: 100;
  }
}

.asset-slideout {
  &.ant-drawer-content-wrapper:not(.relative-height) {
    width: 1370px !important;
  }

  .ant-drawer-content {
    .ant-drawer-wrapper-body {
      height: 100% !important;
    }
  }

  .ant-drawer-header,
  .ant-drawer-content {
    background: vars.$DM700 !important;
  }
}

.create-asset-slideout {
  &.ant-drawer-content-wrapper:not(.relative-height) {
    width: 400px !important;
  }

  .ant-drawer-content {
    .ant-drawer-wrapper-body {
      height: 100% !important;
    }
  }

  .ant-drawer-header,
  .ant-drawer-content {
    background: vars.$DM700 !important;
  }
}

.systemStatusPopover {
  .ant-popover-inner,
  .ant-popover-arrow-content {
    background: vars.$DM800 !important;
  }

  .ant-popover-inner-content {
    min-width: 320px !important;
    max-height: 440px !important;
  }
}

.reportExportPopover {
  .ant-popover-inner,
  .ant-popover-arrow-content {
    background: vars.$DM800 !important;
  }

  .ant-popover-inner-content {
    width: 350px !important;
    height: 300px !important;
    padding-right: 0 !important;
  }
}

.drawer-class-closed {
  width: calc(100vw - 455px) !important;
}

.word-break {
  word-break: break-word;
}

.input-wrapper {
  input:focus + label {
    background: vars.$gray-10;
    border-radius: 4px;
    display: inline-block;
    font-size: 10px;
    font-weight: 500;
    height: 20px;
    left: 2px;
    letter-spacing: 0.3px;
    line-height: 20px;
    margin: 0;
    padding: 0 10px;
    top: 1px !important;
    width: 80%;
    z-index: 1;
  }
  textarea:focus + label {
    background: vars.$gray-10;
    border-radius: 4px;
    display: inline-block;
    font-size: 10px;
    font-weight: 500;
    height: 20px;
    left: 2px;
    letter-spacing: 0.3px;
    line-height: 20px;
    margin: 0;
    padding: 0 10px;
    top: 1px !important;
    width: 80%;
    z-index: 1;
  }
}

.input-wrapper.input-active {
  textarea + label {
    background: vars.$gray-10;
    border-radius: 4px;
    display: inline-block;
    font-size: 10px;
    font-weight: 500;
    height: 20px;
    left: 2px;
    letter-spacing: 0.3px;
    line-height: 20px;
    margin: 0;
    padding: 0 10px;
    top: 1px !important;
    width: 98%;
    z-index: 1;
  }
}

.vfi-textarea {
  background: dark-mode.$field_background_01;
  border: 2px solid dark-mode.$border_02 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: dark-mode.$text_primary !important;
  padding: 8px !important;
  resize: none;
  height: 70px;

  &.readOnly {
    background: transparent !important;
    pointer-events: none;
  }

  &::placeholder {
    color: dark-mode.$text_tertiary;
    font-size: 14px;
    font-weight: 400;
  }
}

.vfi-assignee-selector {
  background: dark-mode.$field_background_01 !important;
  border: 2px solid dark-mode.$border_02 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: dark-mode.$text_primary !important;
  padding: 8px !important;
  min-height: 40px !important;
  text-align: start !important;

  &.readOnly {
    background: transparent !important;
    pointer-events: none;
  }
}

.vfi-label {
  color: dark-mode.$text_secondary;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px;

  &.disabled {
    color: dark-mode.$text_disabled;
  }
}

.vfi-range-selector {
  background: dark-mode.$field_background_01 !important;
}

.snooze-input-wrapper {
  input {
    background: vars.$gray-10;
    border-radius: 4px;
    display: inline-block;
    font-size: 10px;
    font-weight: 500;
    height: 32px;
    width: 100%;
    left: 2px;
    letter-spacing: 0.3px;
    line-height: 20px;
    margin: 0;
    padding: 0 10px;
    top: 1px !important;
    z-index: 1;
  }
}

.decline-input-wrapper {
  input {
    background: vars.$gray-10;
    border-radius: 4px;
    display: inline-block;
    font-weight: 500 !important;
    height: 46px;
    width: 100%;
    left: 2px;
    letter-spacing: 0.3px;
    line-height: 20px;
    margin: 0;
    padding: 0 10px;
  }
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-lower {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize-first::first-letter {
  text-transform: uppercase !important;
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

.float-left {
  float: left;
}

.show-on-hover {
  display: none;
}

.left-align-text {
  text-align: left;
}

.right-align-text {
  text-align: right;
}

// Dashboard styles
.smallChart {
  width: 23%;
}

.medChart {
  width: 37.5%;
}

.largeChart {
  width: 61.5%;
}

.largeChartHeight {
  height: 389px;
}

.overflow-auto {
  overflow: auto !important;
}

.list-style-none {
  list-style: none;
}

.underline {
  text-decoration: underline;
}

.text-decoration-none {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.border-solid {
  border-style: solid;
}

.filterMultiSelect .ant-select-selection--multiple {
  max-width: 229px;
}

.square {
  background-color: vars.$gray-10;
  height: 24px;
  width: 24px;
}

.word-break-all {
  word-break: break-all;
}

.white-space-nowrap {
  white-space: nowrap;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.vw100 {
  width: 100vw !important;
}

.vh100 {
  height: 100vh !important;
}

.tab-disabled {
  background-color: vars.$gray-8;
  border: 2px solid vars.$gray-8;
  pointer-events: none;

  span {
    color: vars.$neutral-7;
  }
}

// Responsive

.copyright {
  @include breakpoints.media-breakpoint-down(sm) {
    margin-bottom: 60px;
  }
}

.vfi-logo {
  @include breakpoints.media-breakpoint-down(sm) {
    height: 85px;
    width: 210px;
  }
  height: 109px;
  width: 270px;
}

.login_box {
  @include breakpoints.media-breakpoint-down(sm) {
    height: 100vh;
    width: 100vw;
  }
  background: vars.$gray-8;
  min-height: 500px;
  width: 500px;
}

.mobile-navigation {
  .ant-drawer-content {
    width: 250px !important;
    .ant-drawer-wrapper-body {
      height: 100% !important;
      background-color: vars.$gray-8;
      .ant-drawer-body {
        height: 100%;
      }
    }

    @include breakpoints.media-breakpoint-down(lg) {
      @include mixins.fixed-full-page;
    }
  }
}

.mobile-main-header {
  padding-top: 25px;
}

.os-ios {
  padding-top: calc(env(safe-area-inset-top) - 20px);
  .ant-drawer-body,
  .ant-drawer-header,
  .ant-modal-close {
    padding-top: calc(env(safe-area-inset-top) - 20px);
  }
  .ant-modal-header {
    padding-top: calc(env(safe-area-inset-top)) !important;
  }
  .main-header {
    padding-top: 10px;
  }
  .mobile-main-header {
    padding-top: 20px;
  }
  .ant-notification-notice {
    @include breakpoints.media-breakpoint-down(sm) {
      padding-top: calc(env(safe-area-inset-top));
    }
  }
  .ant-notification-notice-close {
    @include breakpoints.media-breakpoint-down(sm) {
      padding-top: calc(env(safe-area-inset-top));
    }
  }
  .ant-select-selection-search {
    width: auto !important;
  }
  .ant-select-multiple,
  .ant-select-selection-search:first-child,
  .ant-select-selection-search-input {
    margin-left: 0 !important;
  }
}

.ios-header {
  padding-top: calc(env(safe-area-inset-top) - 1px);
  .ant-modal-header {
    padding-top: calc(env(safe-area-inset-top)) !important;
  }
}

.mobile-alarm-details {
  .ant-drawer-content {
    @include breakpoints.media-breakpoint-down(lg) {
      padding-left: 25px !important;
      padding-right: 25px !important;
      height: 100vh !important;
    }

    @include breakpoints.media-breakpoint-down(sm) {
      @include mixins.fixed-full-page;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    @include breakpoints.media-breakpoint-down(md) {
      background: vars.$DM600 !important;
    }

    overflow-y: hidden;
  }

  .ant-drawer-body {
    @include breakpoints.media-breakpoint-down(md) {
      background: vars.$DM600 !important;
    }
  }
}

.associated-alarms-viewport {
  .ant-collapse-content-box {
    @include breakpoints.media-breakpoint-down(md) {
      background: vars.$gray-9 !important;
    }
  }
}

.ant-notification-notice {
  @include breakpoints.media-breakpoint-down(sm) {
    width: 100% !important;
    max-width: 100% !important;
  }

  background: vars.$gray-7 !important;
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  min-width: 416px !important;

  .ant-notification-notice-description {
    color: vars.$gray-1 !important;
  }
}

.ant-picker-prev-icon {
  left: 34px !important;
  &:before {
    @include mixins.ant-calendar-icon-btn;
  }
  &:after {
    @include mixins.ant-calendar-icon-btn;
  }
}

.ant-picker-super-prev-icon {
  &:before {
    @include mixins.ant-calendar-icon-btn;
  }
  &:after {
    @include mixins.ant-calendar-icon-btn;
  }
}
.ant-picker-next-icon {
  right: 34px !important;
  &:before {
    @include mixins.ant-calendar-icon-btn;
  }
  &:after {
    @include mixins.ant-calendar-icon-btn;
  }
}

.ant-picker-super-next-icon {
  &:before {
    @include mixins.ant-calendar-icon-btn;
  }
  &:after {
    @include mixins.ant-calendar-icon-btn;
  }
}

.confidence-badge {
  border-radius: 4px;
  height: 32px;
  width: 32px;
}

.closeWork {
  .defaultInput {
    min-width: 0 !important;
  }
}

.ant-notification {
  @include breakpoints.media-breakpoint-down(sm) {
    left: 0;
    right: 0 !important;
    margin-left: 0 !important;
    bottom: 0 !important;
  }
}

.mobile-toast {
  @include breakpoints.media-breakpoint-down(sm) {
    width: 100vw;
  }
}

.v-tab-list {
  list-style: none;
  padding-left: 0;
}

.v-tab {
  background-color: vars.$gray-8;
  border: 2px solid vars.$gray-8;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
  height: 40px;
  text-align: center;
  width: 150px;
}

.v-tab.active {
  background-color: vars.$gray-10;
  border: 2px solid vars.$gray-10;
  border-bottom: 1.5px solid vars.$neutral-1;
}

.mobile-confirm-modal {
  @include breakpoints.media-breakpoint-down(sm) {
    .ant-modal-header {
      text-align: center;
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    .ant-modal-body {
      height: calc(100vh - 143px) !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.mobile-close-work {
  @include breakpoints.media-breakpoint-down(md) {
    min-width: 700px;
  }
  min-width: 900px;

  .ant-modal-body {
    @include breakpoints.media-breakpoint-down(sm) {
      height: calc(100vh - 250px) !important;
    }
  }

  .ant-modal-footer {
    div {
      @include breakpoints.media-breakpoint-down(sm) {
        margin-bottom: 40px !important;
      }
    }
  }

  .ant-modal-content {
    overflow: auto !important;
  }
}

.multi-status-modal {
  @include breakpoints.media-breakpoint-down(sm) {
    .ant-modal-header {
      text-align: center;
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    .ant-modal-body {
      height: 70% !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @include breakpoints.media-breakpoint-down(md) {
    min-width: 700px;
  }

  min-width: 900px;
  padding-bottom: 0;
}

.impact-filters-modal {
  .ant-modal-body {
    max-height: 700px !important;
    overflow-x: scroll;
  }
}

.mobile-triage {
  width: 100vw;
}

.mobile-input-wrapper {
  @include breakpoints.media-breakpoint-down(sm) {
    flex-direction: column;
  }

  .mobile-input {
    @include breakpoints.media-breakpoint-down(sm) {
      max-width: 100% !important;
    }
  }
}

.mobile-associated-modal {
  .ant-modal-content {
    background-color: vars.$gray-9 !important;
  }

  .ant-modal-body {
    padding: 0 !important;
  }
}

.mobile-work-slideout {
  .ant-drawer-content {
    height: 100vh;
    width: 100vw;
  }
}

.work-history-tab-header {
  @include breakpoints.media-breakpoint-down(sm) {
    align-items: start !important;
  }

  .v-tab-list {
    @include breakpoints.media-breakpoint-down(sm) {
      flex-direction: row-reverse;
    }
  }
}

// Ant Slider Styles
.ant-slider-rail,
.ant-slider-track {
  background-color: vars.$neutral-4 !important;
}

.ant-slider-handle {
  background-color: vars.$neutral-7 !important;
  border: solid 2px vars.$neutral-7 !important;
}

.seperator-value-selected {
  .ng-value-container {
    margin-top: 19px;
  }
}

// Ant Timeline Styles
.ant-timeline-item-head-blue {
  color: vars.$DM40 !important;
  border-color: vars.$DM40 !important;
}

.ant-timeline-item-head {
  background-color: vars.$DM40 !important;
}

.ant-timeline-item-tail {
  border-left: 2px solid vars.$B40 !important;
  opacity: 15%;
}

.font-spacer {
  padding-right: 4px;
  padding-bottom: 3px;
}

.ngx-datatable.dark .datatable-body {
  height: auto !important;
}

.batch-alarm-table-actions {
  .ant-dropdown-menu {
    width: 150px !important;
  }
}

.pilot-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: vars.$orange-6;
  color: vars.$neutral-1;
  height: 13px;
  width: 13px;
  border-radius: 2px;

  span {
    font-weight: 500;
    font-size: 10px !important;
    padding-top: 1px;
  }
}

.page-header {
  max-height: 80px;
}

.pill {
  margin-bottom: 5px !important;
  background: vars.$blue-6 !important;
  border-radius: 2px !important;
  color: vars.$neutral-1 !important;
  font-size: 11px !important;
  font-weight: 500 !important;

  &.core {
    background: #aecafb3d !important;
  }
}

.ngx-datatable.dark.jump-to-table .datatable-body {
  max-height: 420px !important;
  width: 100% !important;
  overflow-y: visible;
}

.ngx-datatable.dark.jump-to-table .datatable-header {
  width: 100% !important;
}

.ngx-datatable.dark.jump-to-table .datatable-body::-webkit-scrollbar:hover {
  height: 16px;
  width: 16px;
}

.ngx-datatable.dark.jump-to-table
  .datatable-body:hover::-webkit-scrollbar-corner {
  width: 16px;
}

.ngx-datatable.dark.jump-to-table .datatable-body-cell-label {
  display: contents;
}

.ngx-datatable.dark.jump-to-table .datatable-row-wrapper {
  cursor: pointer;
}

.white-space-break {
  white-space: break-spaces;
}
.zero-default-height-options.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option {
  padding-top: 0;
  padding-bottom: 0;
}
.zero-default-height-options.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option
  .item {
  padding-top: 5px;
  padding-bottom: 5px;
}

.betaIcon {
  border: 2px solid vars.$orange-6;
  background: vars.$orange-6;
  color: vars.$neutral-1 !important;
}

.select-value-padding {
  .ng-value {
    margin-top: 15px;
  }
}

.reset-btn {
  @include breakpoints.media-breakpoint-down(sm) {
    margin-top: 85px;
  }
}

.hidden-button-dropdown {
  .ng-dropdown-panel {
    width: auto !important;
  }
}

.filter-pills-wrapper {
  min-height: 56px;
  padding: 12px;
  background-color: vars.$DM800;
  border-radius: 8px;
}

.filter-pills {
  background-color: vars.$DM500;
  height: 32px;
  border-radius: 4px;
  padding: 5px 12px;
}

code-input {
  span {
    input {
      width: 40px !important;
      min-width: 40px !important;
      max-width: 40px !important;
    }
  }
}

.multi-level-select-popover {
  .ant-popover-inner {
    background-color: transparent !important;
    box-shadow: none !important;
  }

  .ant-popover-inner-content {
    width: 100%;
    padding: 0;
  }

  .ant-popover-arrow {
    display: none;
  }
}

//Checkbox Styles
.vfi-checkbox {
  appearance: none;
  height: 14px;
  width: 14px;
  background-color: none !important;
  border: 1px solid vars.$DM50 !important;
  border-radius: 2px;
}

input.vfi-checkbox[type='checkbox']:checked {
  background: vars.$blue !important;
  border: 1px solid vars.$blue !important;
}

// multi level select styles
.multi-level-select {
  min-width: 60px;

  .dropdown-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: vars.$gray-10;
    border-radius: 4px;
    width: 100%;
    padding: 12px;
    border: none;
    font-size: 16px;
    color: #8c8c8c;
    border: 1px solid vars.$gray-10;

    .dropdown-label-placeholder {
      text-align: left;
      font-weight: 400;
    }

    .value {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      label {
        font-size: 0.6em;
        font-weight: bold;
      }

      .dropdown-label {
        padding: 8px;
        color: vars.$white;
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        overflow-x: scroll;
        width: 100%;
      }
    }
  }
}

// Entity Management Styles
.entityRelationshipHeight {
  height: calc(100vh - 190px);
}

.snoozeEntityRelationshipHeight {
  height: calc(100vh - 225px);
}

.entityHistoryHeight {
  height: calc(100vh - 213px);
}

.snoozeEntityHistoryHeight {
  height: calc(100vh - 330px);
}

// Control Center Animations
@keyframes flash {
  0% {
    background-color: #577b90;
    border: 2px solid transparent;
  }
  50% {
    background-color: #577b90;
    border: 2px solid #fe7d96;
  }
  100% {
    background-color: vars.$gray-9;
    border-color: vars.$red-7;
  }
}

@keyframes entity-flash {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: vars.$gray-5;
  }
  100% {
    background-color: transparent;
  }
}

@keyframes entity-text-flash {
  0% {
  }
  50% {
    color: vars.$DM00;
  }
  100% {
  }
}

// Existing Work Ticket Modal Styles
.existing-work-tickets-modal {
  .ant-modal-body {
    @include breakpoints.media-breakpoint-down(sm) {
      overflow: auto;
      min-height: 82vh;
    }
    max-height: 600px;
    overflow: auto;
  }
}
.existing-work-tickets-details-modal {
  .ant-modal-body {
    @include breakpoints.media-breakpoint-down(sm) {
      overflow: auto;
      min-height: 82vh;
    }
    padding: 10px !important;
    height: 700px;
  }
}

// Asset Selector Modal Styles
.asset-selector-modal {
  .ant-modal-body {
    padding-top: 1px !important;
    height: 700px;
  }
}

// AG-Grid Styles
.ag-text-field-input {
  height: 32px;
}

.ag-center-cols-container {
  position: relative;
  .ag-row {
    border-left: 3px solid transparent;
  }
  .new-row {
    border-left: 3px solid vars.$blue-7;
  }
}

.ag-selection-checkbox.ag-invisible {
  display: none !important;
}

// AG-Grid scrollbar styles
.ag-root ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.ag-root ::-webkit-scrollbar-thumb {
  background: vars.$neutral-6;
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: padding-box;
}

.ag-root ::-webkit-scrollbar-thumb:hover {
  border: 0;
}

.ag-root ::-webkit-scrollbar-track {
  background: transparent;
}

.ag-grid-alarm-options {
  margin-left: -13px;
}

.hide-header-name {
  .ag-header-cell-text {
    display: none;
  }
}

.ant-dropdown-menu {
  @include breakpoints.media-breakpoint-up(md) {
    min-width: 240px !important;
  }

  color: dark-mode.$text_primary !important;
  background: vars.$DM600 !important;
  border: 1px solid vars.$DM400 !important;
  border-radius: 4px !important;
  padding: 7px !important;
}

.ant-menu-submenu {
  min-width: 128px !important;
  top: -10px;

  .ant-dropdown-menu {
    @include breakpoints.media-breakpoint-up(md) {
      min-width: 128px !important;
    }
  }
}

.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled {
  .ant-dropdown-menu-submenu-title,
  .ant-dropdown-menu-submenu-arrow-icon {
    color: dark-mode.$text_disabled !important;
    background-color: transparent !important;
    font-size: 14px !important;
  }
}
.ant-dropdown-menu-item-group-list {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.ant-dropdown-menu-submenu-title {
  color: dark-mode.$text_primary !important;
  padding: 10px !important;
  line-height: 20px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
}

.ant-dropdown-menu-item-disabled {
  color: dark-mode.$text_disabled !important;
  background: transparent !important;
}

.ant-dropdown-menu-submenu-title
  .ant-dropdown-menu-submenu-expand-icon
  .ant-dropdown-menu-submenu-arrow-icon {
  color: dark-mode.$text_primary !important;
}

.ant-dropdown-menu-item-group-title {
  color: dark-mode.$text_secondary !important;
  padding: 9px !important;
}

.ant-dropdown-menu-item {
  padding: 10px !important;
  line-height: 20px !important;
  font-size: 14px !important;
  border-radius: 4px !important;

  &:hover {
    background: color.adjust(vars.$DM400, $alpha: -0.08) !important;
  }
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 1001 !important;
}

.snooze-reason-modal {
  z-index: 1001 !important;
}

.disable-list {
  pointer-events: none;

  &.ant-dropdown-menu-item:hover {
    background: transparent !important;
  }

  &.ant-dropdown-menu-submenu {
    .ant-menu-title-content {
      color: vars.$neutral-7 !important;
    }
  }

  a {
    color: vars.$neutral-7 !important;
  }

  i {
    pointer-events: auto;
    color: vars.$neutral-1;
  }
}

.disabled-star {
  color: vars.$gray-5;
  opacity: 0.5;
}

.socketAlert {
  top: 0;
  border: 3px solid vars.$blue-7;
  z-index: 1;

  .socketText {
    position: absolute;
    left: -3px;
    bottom: -1px;
    width: 150px;
    background: vars.$blue-7;
    display: flex;
    justify-content: center;
    border-radius: 4px;
  }

  .markAsSeenText {
    position: absolute;
    right: -3px;
    bottom: -1px;
    width: 150px;
    background: vars.$blue-7;
    display: flex;
    justify-content: center;
    border-radius: 4px;
  }
}

.lensCategoryBorder {
  margin: 15px 0;
}

.check-green {
  color: dark-mode.$icon_success;
}

.andClause {
  width: 40px;
  height: 32px;
}

.notification-template-variable-dropdown {
  overflow-x: scroll !important;
  height: 330px !important;
}

// Dragula styles
.gu-transit {
  opacity: 0.8;
}

// Asset Status
.GREEN {
  color: vars.$green-7;
}
.RED {
  color: vars.$red-7;
}
.YELLOW {
  color: vars.$yellow-7;
}

// VF Action Menu Styles
.vf-actions-menu {
  .ant-dropdown-menu-item-group-title {
    color: vars.$DM00 !important;
    font-weight: 500;
    border-bottom: 1px solid vars.$DM300;
    margin-bottom: 5px;
  }
}

