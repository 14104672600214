@use 'sass:color';
@use '../vars';
@use 'mixins';

/*  ---------------------------------------------------------
1. General reset
---------------------------------------------------------*/
html {
  height: 100%;
}

body {
  -ms-overflow-style: scrollbar;
  height: 100%;
  min-width: vars.$base-min-width;
  overflow: hidden;

  &:not(.is-mobile):not(.is-mac) {
    .scrollable-holder {
      overflow: hidden !important;
    }

    .scrollable-frame {
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }
}

a,
.btn,
button {
  text-decoration: none;
  @extend %transition !optional;
  a &:focus,
  &:active {
    outline: none;
  }
}

button {
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
}

h1,
.h1 {
  line-height: 1.2;
}

h2,
.h2 {
  display: flex;
  line-height: 1.2;

  [class*='icon-'] {
    font-size: 16px;
    line-height: 24px;
    margin: 0 5px 0 0;

    // @include media-breakpoint-up(md) {
    // 	margin: 0 10px 0 0;
    // }

    &.large {
      font-size: 20px;

      // @include media-breakpoint-up(md) {
      // 	margin: 0 14px 0 0;
      // }
    }
  }
}

h3,
.h3 {
  margin-bottom: 13px;
  text-transform: uppercase;
}

time {
  display: inline-flex;
  flex-shrink: 0;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;

  span {
    + span {
      padding: 0 0 0 10px;
      position: relative;

      // @include media-breakpoint-up(md) {
      // 	padding: 0 0 0 13px;
      // }

      &:before {
        content: '•';
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 10px;

        // @include media-breakpoint-up(md) {
        // 	width: 12px;
        // }
      }
    }
  }
}

.text-ornage {
  color: vars.$orange !important;
}

.bg-ornage {
  background: vars.$orange !important;
}

[class*='icon-'].square {
  @include mixins.size(16px);
  align-items: center;
  background: vars.$gray-10;
  border-radius: 2px;
  color: vars.$white;
  display: inline-flex;
  flex-shrink: 0;
  font-size: vars.$regular-14;
  height: 24px;
  justify-content: center;
  margin: 0 7px 0 0;
  padding: 3px 2px;
  text-align: center;
  width: 24px;

  // @include media-breakpoint-up(md) {
  // 	margin: 0 12px 0 0;
  // }

  &.bg-light {
    background-color: vars.$gray03 !important;
  }

  &.bg-dark {
    background-color: vars.$gray02 !important;
  }

  &.large {
    @include mixins.size(20px);
    font-size: 10px;
    padding: 5px 3px;

    // @include media-breakpoint-up(md) {
    // 	@include size(24px);
    // 	padding: 4px 3px;
    // 	font-size: 16px;
    // }
  }
}

.btn {
  font-weight: bold;
  min-width: 120px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;

  // @include media-breakpoint-up(md) {
  // 	min-width: 163px;
  // }
}

.btn-secondary {
  color: inherit;

  &:hover {
    background: color.adjust(vars.$secondary, $lightness: -2%);
    border-color: color.adjust(vars.$secondary, $lightness: -2%);
  }
}

.btn-circle {
  @include mixins.size(30px);
  background: vars.$gray-600;
  border: 1px solid transparent;
  border-radius: 100%;
  color: inherit;
  display: block;
  min-width: inherit;
  padding: 5px;
  position: relative;

  // @include media-breakpoint-up(md) {
  // 	@include size(36px);
  // }

  &:hover {
    background: vars.$gray-700;
    color: vars.$white;
  }

  i {
    font-size: 14px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    // @include media-breakpoint-up(md) {
    // 	font-size: 16px;
    // }
  }
}

.btn-pdf {
  @include mixins.align(top);
  font-weight: bold;
  padding: 0 0 0 26px;
  position: relative;

  &:before {
    @include mixins.icon('\e922');
    font-size: 18px;
    left: 0;
    line-height: 1;
    position: absolute;
    top: 0;
  }
}

.btn-add {
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  padding: 0 0 0 21px;
  position: relative;
  text-transform: uppercase;

  &:before {
    @include mixins.icon('\e91a');
    left: 0;
    line-height: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-55%);
  }
}

button.btn-close {
  background: none;
  border: 0;
  padding: 0;
}

.btn-close {
  color: inherit;
  font-size: 14px;
  line-height: 1;
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);

  &:hover {
    color: vars.$white;
  }

  i {
    display: block;
  }
}

label {
  cursor: pointer;
  @extend %transition !optional;
  letter-spacing: 0.5px;

  &:hover {
    color: vars.$white;
  }
}

.form-group {
  position: relative;

  .label-icon,
  .label-img {
    font-size: 16px;
    line-height: 1;
    position: absolute;
    right: 12px;
    top: 12px;

    i {
      display: block;
    }

    img {
      @include mixins.responsive-image;
    }

    + .form-control {
      padding-right: 36px;
    }
  }

  .label-img {
    @include mixins.size(24px);
    border-radius: 100%;
    overflow: hidden;
    right: 7px;
    top: 7px;
  }
}

.input-wrapper {
  position: relative;

  .form-group & {
    margin-bottom: 0;
  }

  &.input-active {
    label {
      font-size: vars.$regular-10;
      font-weight: 500;
      letter-spacing: 0.3px;
      top: 6px;
    }
  }

  label {
    color: vars.$neutral-7;
    font-size: vars.$regular-16;
    font-weight: 500;
    left: 12px;
    @extend %transition !optional;
    line-height: 16px;
    pointer-events: none;
    position: absolute;
    text-transform: capitalize;
    top: 19px;
  }

  .form-control {
    background: vars.$gray-10;
    padding-bottom: 0.375rem;
    padding-left: 0.8rem;
    padding-top: 20px;
  }
}

textarea.form-control {
  border: transparent;
  border-radius: 4px;
  height: 70px;
  outline: none;
  resize: none;
  width: 100%;
}
/* chckbox styles */
// .checkbox {
// 	line-height: 16px;
// 	font-weight: bold;
// 	text-transform: uppercase;
// 	cursor: pointer;
// 	overflow: hidden;

// 	&.only {
// 		@include size(16px);

// 		span {
// 			padding: 0 0 0 16px;
// 		}
// 	}

// 	input[type=checkbox] {
// 		display: none;
// 		clip: rect(0 0 0 0);

// 		&:checked ~ {
// 			span:before {
// 				content: "\e91e";
// 			}
// 		}
// 	}

// 	span {
// 		min-height: 16px;
// 		@include align(top);
// 		padding: 0 0 0 25px;
// 		position: relative;

// 		&:before {
// 			@include icon("\e91d");
// 			font-size: 16px;
// 			line-height: 1;
// 			position: absolute;
// 			top: 0;
// 			left: 0;
// 		}
// 	}
// }
/* switcher styles */
.switcher {
  @include mixins.size(40px, 20px);
  border-radius: 20px;
  //overflow: hidden;

  input[type='checkbox'] {
    clip: rect(0 0 0 0);
    display: none;

    &:checked ~ {
      span {
        background: vars.$success;

        &:before {
          left: 24px;
        }
      }
    }
  }

  span {
    @include mixins.size(100%);
    background: vars.$gray-600;
    border-radius: 20px;
    display: block;
    position: relative;
    @extend %transition !optional;

    &:before {
      @include mixins.size(12px);
      @include mixins.pseudo-elements(4px, auto, auto, 4px);
      background: vars.$white;
      border-radius: 100%;
      @extend %transition !optional;
    }
  }
}
/* range styles */
.range-wrapper {
  display: block;
  height: 18px;
  padding: 1px 0;
  position: relative;

  input[type='range'] {
    -webkit-appearance: none;
    //height: 13px;
    background: transparent;
    display: block;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;

    &:focus {
      outline: none;

      &::-webkit-slider-runnable-track {
        // background: $custom-range-track-bg;
      }

      &::-ms-fill-lower {
        // background: $custom-range-track-bg;
      }

      &::-ms-fill-upper {
        // background: $custom-range-track-bg;
      }
    }

    &::-webkit-slider-runnable-track {
      // @include size($custom-range-track-width, $custom-range-track-height);
      animate: 0.2s;
      border: 0;
      // background: $custom-range-track-bg;
      // border-radius: $custom-range-thumb-border-radius;
      box-sizing: border-box;
      cursor: pointer;
    }

    &::-webkit-slider-thumb {
      @include mixins.size(12px);
      -webkit-appearance: none;
      background: vars.$danger;
      border: 2px solid vars.$gray-200;
      border-radius: 100%;
      box-shadow: 0 1px 2px rgba(vars.$black, 0.2);
      box-sizing: border-box;
      cursor: pointer;
      margin-top: -3.6px;
    }

    &::-moz-range-track {
      // @include size($custom-range-track-width, $custom-range-track-height);
      animate: 0.2s;
      border: 0;
      // background: $custom-range-track-bg;
      // border-radius: $custom-range-thumb-border-radius;
      box-sizing: border-box;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      @include mixins.size(12px);
      background: vars.$danger;
      border: 2px solid vars.$gray-200;
      border-radius: 100%;
      box-shadow: 0 1px 2px rgba(vars.$black, 0.2);
      box-sizing: border-box;
      cursor: pointer;
    }

    &::-ms-track {
      // @include size($custom-range-track-width, $custom-range-track-height);
      animate: 0.2s;
      background: transparent;
      border-color: transparent;
      border-width: 5px 0;
      color: transparent;
      cursor: pointer;
    }

    &::-ms-thumb {
      @include mixins.size(12px);
      background: vars.$danger;
      border: 2px solid vars.$gray-200;
      border-radius: 100%;
      box-shadow: 0 1px 2px rgba(vars.$black, 0.2);
      box-sizing: border-box;
      cursor: pointer;
    }

    &::-ms-fill-lower {
      // background: $custom-range-track-bg;
      // border-radius: $custom-range-thumb-border-radius;
    }

    &::-ms-fill-upper {
      // background: $custom-range-track-bg;
      // border-radius: $custom-range-thumb-border-radius;
    }
  }
}
/* dropdown styles */
.dropdown {
  flex-shrink: 0;

  &.active {
    .dropdown-menu {
      display: block;
    }
  }
}

.dropdown-toggle {
  color: inherit;
  font-weight: bold;
  padding-right: 24px;
  position: relative;
  text-align: left;
  text-transform: uppercase;

  &:after {
    @include mixins.icon('\e906');
    border: 0;
    font-size: 8px;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    color: vars.$white;
  }

  &:not(.btn) {
    background: none;
    border: 0;

    &:hover {
      color: vars.$white;
    }
  }

  &.btn-secondary {
    background: vars.$gray-700;
    border-color: vars.$gray-700;

    .show > & {
      background: vars.$gray-700;
    }
  }

  &.btn {
    color: vars.$gray-200;
    display: flex;
    font-size: 12px;
    padding-right: 35px;

    &:after {
      color: vars.$gray-500;
      right: 15px;

      // @include media-breakpoint-up(md) {
      // 	right: 20px;
      // }
    }

    &.btn-danger {
      &:after {
        color: vars.$white;
      }
    }

    &:not(.btn-secondary):not(.btn-danger) {
      color: vars.$white;

      &:after {
        color: vars.$white;
      }
    }

    [class*='icon-'] {
      color: vars.$white;
    }
  }

  .title {
    flex-grow: 1;
    font-size: 12px;
    font-weight: inherit;
    margin: 0 10px 0 0;
  }
}

.dropdown-menu {
  // box-shadow: $dropdown-box-shadow;

  &[x-placement='top-start'] {
    // margin: 0 0 $dropdown-spacer;

    &:after {
      bottom: auto;
      top: 100%;
      transform: scaleY(-1);
    }
  }

  &.right-align {
    left: auto !important;
    right: -1px;
    top: 100% !important;
    transform: none !important;

    &[x-placement='top-start'] {
      bottom: 100%;
      top: auto !important;
    }
  }

  &:after {
    @include mixins.pseudo-elements(auto, 5px, 100%, auto);
    border-style: solid;
    border-width: 0 7px 8px;
    // border-color: transparent transparent $dropdown-bg;
    z-index: 9;
  }
}

.dropdown-item {
  &:first-child {
    // border-radius: $dropdown-border-radius $dropdown-border-radius 0 0;
  }

  &:last-child {
    // border-radius: 0 0 $dropdown-border-radius $dropdown-border-radius;
  }
}

.select-dropdown {
  margin: 0 0 12px;

  .form-group & {
    margin: 0;
  }

  .dropdown-toggle {
    min-height: 40px;
    width: 100%;
  }

  [class*='icon-'] {
    @include mixins.size(16px);
    @include mixins.align(top);
    line-height: 16px;
    margin: 1px 8px 1px 0;
    text-align: center;
  }

  .dropdown-menu {
    background: vars.$gray-700;
    margin: 2px 0 0;
    max-height: 160px;
    overflow-y: auto;
    width: 100%;

    &[x-placement='top-start'] {
      margin: 0 0 2px;
    }

    &:after {
      display: none;
    }
  }
}

.section-dropdown {
  flex-shrink: 0;
  margin: 0 0 0 12px;
  width: 24px;

  .dropdown-toggle {
    color: inherit;
    display: block;
    font-size: 16px;
    line-height: 1;
    padding: 1px 4px;

    &:after {
      display: none;
    }

    i {
      display: block;
    }
  }
}
/* tag styles */
.tag {
  color: vars.$white;
  font-size: 12px;
  line-height: 18px;
  margin: 0 13px 0 0;
  padding: 0 0 0 18px;
  position: relative;

  // @include media-breakpoint-up(md) {
  // 	padding: 0 0 0 21px;
  // }

  .btn-close {
    color: vars.$gray-500;
    left: 0;
    right: auto;
    @extend %transition !optional;

    &:before {
      @include mixins.icon('\e908');
    }

    &:hover {
      color: inherit;
    }
  }
}
/* title bar with dropdown styles */
.title-bar {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  margin: 0 0 10px;
  padding: 0 30px 0 0;
  position: relative;

  // @include media-breakpoint-up(md) {
  // 	padding: 0 36px 0 0;
  // }

  [class*='icon-'] {
    color: vars.$gray-200;
    margin: 0 6px 0 0;
    text-align: center;
    width: 16px;
  }

  .title {
    color: vars.$gray-200;
    flex-shrink: 0;
    font-size: 12px;
    margin: 0 9px 0 0;
    text-transform: uppercase;

    &:nth-last-child(2) {
      flex-grow: 1;
    }
  }

  time {
    flex-grow: 1;
    margin: 0 10px 0 0;
  }

  .section-dropdown {
    margin: 0;
    position: absolute;
    right: 0;
    top: 1px;
  }
}
/* heading bar styles */
button.heading-bar {
  background: none;
  border: 0;
  color: inherit;
  padding: 0;
  text-align: left;
}

.heading-bar {
  display: flex;
  font-size: 16px;
  margin: 0 0 10px;
  position: relative;

  .section-dropdown {
    margin: 3px 0 3px 10px;
  }

  > [class*='icon-'] {
    margin: 4px 7px 4px 0;

    // @include media-breakpoint-up(md) {
    // 	margin: 4px 12px 4px 0;
    // }
  }

  .id {
    flex-shrink: 0;
    margin: 0 7px 0 0;
    max-width: 56px;
    opacity: 0.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    // @include media-breakpoint-up(md) {
    // 	margin: 0 12px 0 0;
    // }
  }

  h2 {
    margin: 0;
  }

  .heading {
    color: vars.$white;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    // @include media-breakpoint-up(md) {
    // 	white-space: normal;
    // }
  }

  time {
    padding: 3px 0;
  }
}

.title {
  color: vars.$gray-200;
  font-size: 14px;
}
/* alert styles */
.alert {
  padding-right: 35px;

  // @include media-breakpoint-up(md) {
  // 	padding: 18px 45px 18px 18px;
  // }

  &.alert-light {
    background: vars.$gray-100;
    color: vars.$black;

    .close {
      background: vars.$gray04;
    }
  }

  p {
    &:last-child {
      margin: 0;
    }
  }

  .close {
    @include mixins.size(19px);
    background: rgba(vars.$white, 0.6);
    border-radius: 100%;
    color: inherit;
    font-size: 7px;
    line-height: 1;
    opacity: 1;
    padding: 6px 5px;
    position: absolute;
    right: 8px;
    text-align: center;
    top: 8px;

    // @include media-breakpoint-up(md) {
    // 	@include size(21px);
    // 	padding: 7px 5px;
    // 	top: 16px;
    // 	right: 18px;
    // }

    i {
      display: block;
    }
  }
}
/* System Notification Alert styles */
.system-notification {
  bottom: 10px;
  left: 0;
  margin: 0;
  max-width: 100%;
  opacity: 0;
  position: absolute;
  visibility: hidden;
  z-index: 999 !important;
  @extend %transition !optional;

  // @include media-breakpoint-up(md) {
  // 	min-width: 400px;
  // 	max-width: calc(100% - 40px);
  // 	bottom: 20px;
  // 	left: 20px;
  // }

  // @include media-breakpoint-up(lg) {
  // 	min-width: 531px;
  // }

  &.active {
    opacity: 1;
    visibility: visible;
  }
}
/* progress bar styles */
.progress {
  &-bar {
    background: vars.$blue;
    // border-radius: $progress-border-radius;
  }
}
/* pie cart styles */
.pie-cart {
  @include mixins.size(74px);
  flex-shrink: 0;
  position: relative;

  .value {
    color: vars.$white;
    font-size: 10px;
    left: 10px;
    position: absolute;
    right: 10px;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);

    [class*='icon-'] {
      font-size: 8px;
      line-height: inherit;
      margin: 0 3px 0 0;
    }
  }

  img {
    // @include responsive-image;
  }
}

.img-full-width {
  display: block;
  height: auto;
  width: 100%;
}
/* main container styles */
#wrapper {
  height: 100%;
  overflow: hidden;
}
