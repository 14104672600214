@use 'libs/styles/vars';

@each $name, $value in vars.$lines {
  .ellipsis-#{$name} {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: $value;
    -webkit-box-orient: vertical;
  }
}
