@use 'sass:map';
@use 'libs/styles/vendors/bootstrap/mixins/breakpoints';
@use 'libs/styles/vars';

@each $breakpoint in map.keys(vars.$grid-breakpoints) {
  @include breakpoints.media-breakpoint-up($breakpoint) {
    $infix: breakpoints.breakpoint-infix($breakpoint, vars.$grid-breakpoints);

    .d#{$infix}-none {
      display: none !important;
    }

    .d#{$infix}-inline {
      display: inline !important;
    }

    .d#{$infix}-inline-block {
      display: inline-block !important;
    }

    .d#{$infix}-block {
      display: block !important;
    }

    .d#{$infix}-table {
      display: table !important;
    }

    .d#{$infix}-table-row {
      display: table-row !important;
    }

    .d#{$infix}-table-cell {
      display: table-cell !important;
    }

    .d#{$infix}-flex {
      display: flex !important;
    }

    .flex#{$infix}-column {
      flex-flow: column !important;
    }
    .flex#{$infix}-column-reverse {
      flex-flow: column-reverse !important;
    }

    .flex#{$infix}-row {
      flex-flow: row !important;
    }

    .d#{$infix}-inline-flex {
      display: inline-flex !important;
    }

    .flex#{$infix}-justify-content-center {
      justify-content: center !important;
    }

    .flex#{$infix}-justify-content-between {
      justify-content: space-between !important;
    }
  }

  @include breakpoints.media-breakpoint-down($breakpoint) {
    $infix: breakpoints.breakpoint-infix($breakpoint, vars.$grid-breakpoints);

    .d#{$infix}-down-none {
      display: none !important;
    }

    .d#{$infix}-down-inline {
      display: inline !important;
    }

    .d#{$infix}-down-inline-block {
      display: inline-block !important;
    }

    .d#{$infix}-down-block {
      display: block !important;
    }

    .d#{$infix}-down-table {
      display: table !important;
    }

    .d#{$infix}-down-table-row {
      display: table-row !important;
    }

    .d#{$infix}-down-table-cell {
      display: table-cell !important;
    }

    .d#{$infix}-down-flex {
      display: flex !important;
    }

    .d#{$infix}-down-inline-flex {
      display: inline-flex !important;
    }

    .flex#{$infix}-down-column {
      flex-flow: column !important;
    }

    .flex#{$infix}-down-column-reverse {
      flex-flow: column-reverse !important;
    }

    .flex#{$infix}-down-justify-content-center {
      justify-content: center !important;
    }

    .flex#{$infix}-down-justify-content-between {
      justify-content: space-between !important;
    }
  }
}

.d-none {
  display: none;
}

.display-on-hover {
  display: none;
}
.display-on-hover:hover {
  display: block;
}
